import React from 'react';
import { DateTimePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import moment from 'moment';

moment.locale('pt');

const ModalDatePicker = ({ show, placeholder, selectedDate, handleDateChange }) => {
  return show ? (
    <div className="w-100 d-flex justify-content-center">
      <DateTimePicker
        disableFuture
        keyboard
        fullWidth
        variant="outlined"
        label="Data do registo"
        format="DD/MM/YYYY HH:mm"
        className="col-md-6 mb-3"
        placeholder={placeholder}
        cancelLabel="Sair"
        invalidDateMessage="Data inválida"
        value={moment(selectedDate)
          .utc()
          .toISOString()}
        // value={moment(selectedDate).utc()}
        // onChange={handleDateChange}
        onChange={dateValue => handleDateChange('dataModal', dateValue)}
        ampm={false}
        okLabel="Confirmar"
        mask={value =>
          value
            ? [
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                ':',
                /\d/,
                /\d/,
              ]
            : []
        }
        disableOpenOnEnter
        animateYearScrolling={false}
      />
    </div>
  ) : null;
};

ModalDatePicker.propTypes = {
  show: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedDate: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default ModalDatePicker;
