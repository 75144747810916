import {
  HISTORY_LOADING,
  GET_HISTORY,
  GET_HISTORY_PATIENTS,
  GET_HISTORY_STAFF,
  GET_HISTORY_PAST,
  HISTORY_NOT_LOADING,
  CLEAN_FILTERED,
} from '../actions/types';

const initialState = {
  occurs: [],
  agenda: [],
  tasklist: [],
  patients: [],
  staff: [],
  filtered: false,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_PATIENTS:
      return {
        ...state,
        patients: [...action.payload],
        loading: false,
      };
    case GET_HISTORY_STAFF:
      return {
        ...state,
        staff: [...action.payload],
        loading: false,
      };
    case GET_HISTORY:
      return {
        ...state,
        occurs: action.payload.occurs,
        agenda: action.payload.agenda,
        tasklist: action.payload.tasklist,
        filtered: true,
        loading: false,
      };
    case GET_HISTORY_PAST:
      return {
        ...state,
        occurs: action.payload.occurs,
        agenda: action.payload.agenda,
        tasklist: action.payload.tasklist,
        loading: false,
      };
    case HISTORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAN_FILTERED:
      return {
        ...state,
        filtered: false,
      };
    case HISTORY_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
