import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, dev, boss, ...rest }) =>
  dev ? (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && auth.user.clinica && auth.user.username.indexOf('Dev') !== -1 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/clinica/entrar" />
        )
      }
    />
  ) : boss ? (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && auth.user.clinica && auth.user.permissions.boss ? (
          <Component {...props} />
        ) : (
          <Redirect to="/clinica/entrar" />
        )
      }
    />
  ) : (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && auth.user.clinica ? (
          <Component {...props} />
        ) : (
          <Redirect to="/clinica/entrar" />
        )
      }
    />
  );

PrivateRoute.defaultProps = {
  dev: false,
  boss: false,
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  dev: PropTypes.bool,
  boss: PropTypes.bool,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
