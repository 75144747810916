/* eslint-disable import/first */
/* eslint-disable react/prefer-stateless-function */
import React, { Component, Suspense } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './store/actions/authActions';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt';

// Styling
import './App.css';
// Theme
import Theme from './utils/theme/theme';
// import blue from '@material-ui/core/colors/blue';
import { CssBaseline } from '@material-ui/core';

// Components
import RegisterForm from './components/register/RegisterForm';
import LoginForm from './components/login/LoginForm';
import RecuperarForm from './components/recuperar/RecuperarForm';
import RecoverPasswordForm from './components/recuperar/RecoverPasswordForm';
import ConfirmForm from './components/confirmar/ConfirmForm';
import PrivateRoute from './components/common/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import FullWidthSpinner from './components/common/FullWidthSpinner';
import Dealer from './components/common/Dealer';
import Reload from './components/common/Reload';

const Logs = React.lazy(() => import('./components/logs/Logs'));
const Dashboard = React.lazy(() => import('./components/dashboard/Dashboard'));
const Calendario = React.lazy(() => import('./components/calendar/Calendario'));
const Historico = React.lazy(() => import('./components/history/Historico'));
const Arquivo = React.lazy(() => import('./components/archive/Arquivo'));
const Modelos = React.lazy(() => import('./components/files/Modelos'));
const UserDashboard = React.lazy(() => import('./components/user/UserDashboard'));
const PermissionsDashboard = React.lazy(() => import('./components/user/PermissionsDashboard'));
const UtentesLar = React.lazy(() => import('./components/utentes/lar/UtentesLar'));

const UtenteNovo = React.lazy(() => import('./components/utentes/novo/UtenteNovo'));
const UtenteAtivo = React.lazy(() => import('./components/utentes/ativo/UtenteAtivo'));
const UtenteAtivoMobile = React.lazy(() => import('./components/mobile/UtenteAtivoMobile'));
const GerirMedicacao = React.lazy(() => import('./components/medication/gerir/GerirMedicacao'));
const PrepararMedicacao = React.lazy(() =>
  import('./components/medication/preparar/PrepararMedicacao')
);
const PrepararMedicacaoMOBILE = React.lazy(() =>
  import('./components/mobile/medicacao/preparar/PrepararMedicacao')
);
const UtentesCentro = React.lazy(() => import('./components/utentes/centro-de-dia/UtentesCentro'));
const MobileIndex = React.lazy(() => import('./components/mobile/MobileIndex'));

// Check for token
if (sessionStorage.getItem('jwtToken')) {
  setAuthToken(sessionStorage.getItem('jwtToken'));

  const decoded = jwtDecode(sessionStorage.getItem('jwtToken'));

  store.dispatch(setCurrentUser(decoded));

  // Check for expiredToken
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = '/clinica/entrar';
  }
}

moment.locale('pt');

const commonRoutes = [
  { private: false, path: '/', component: Dealer },
  { private: false, path: '/clinica/entrar', component: LoginForm },
  { private: false, path: '/gestao', component: Reload },
  { private: false, path: '/clinica/registar', component: RegisterForm },
  { private: false, path: '/clinica/recuperar', component: RecuperarForm },
  { private: false, path: '/clinica/utilizador/recuperar/:code', component: RecoverPasswordForm },
  { private: false, path: '/clinica/utilizador/confirmar/:code', component: ConfirmForm },
];

const routes = [
  ...commonRoutes,
  { private: true, path: '/clinica', component: Dashboard },
  { private: true, path: '/clinica/calendario', component: Calendario },
  { private: true, path: '/clinica/utentes/lar', component: UtentesLar },
  {
    private: true,
    path: '/clinica/utentes/centro-de-dia',
    component: UtentesCentro,
  },
  { private: true, path: '/clinica/novo', component: UtenteNovo },
  { private: true, path: '/clinica/utente/:url_name', component: UtenteAtivo },
  { private: true, path: '/clinica/modelos', component: Modelos },
  { private: true, path: '/clinica/medicacao', component: GerirMedicacao },
  { private: true, path: '/clinica/preparar-medicacao', component: PrepararMedicacao },
  { private: true, path: '/clinica/historico', component: Historico },
  { private: true, path: '/clinica/arquivo', component: Arquivo },
  {
    private: true,
    path: '/clinica/alterar-permissoes',
    component: PermissionsDashboard,
    boss: true,
  },
  { private: true, path: '/clinica/utilizador/:username', component: UserDashboard },
  { private: true, path: '/clinica/logs', component: Logs, dev: true },
];

const routesMobile = [
  ...commonRoutes,
  { private: true, path: '/clinica', component: MobileIndex },
  { private: true, path: '/clinica/utente/:url_name', component: UtenteAtivoMobile },
  { private: true, path: '/clinica/preparar-medicacao', component: PrepararMedicacaoMOBILE },
  { private: true, path: '/clinica/utilizador/:username', component: UserDashboard },
  { private: true, path: '/', component: MobileIndex },
  { private: true, path: '/utente/:url_name', component: UtenteAtivoMobile },
  { private: true, path: '/preparar-medicacao', component: PrepararMedicacaoMOBILE },
  { private: true, path: '/utilizador/:username', component: UserDashboard },
];

window.mobilecheck = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

class App extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={Theme}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt" moment={moment}>
              <Router>
                <>
                  <Navbar isMobile={window.mobilecheck()} />
                  {/* <FixedNavbar /> */}
                  <div
                    className="container-fluid"
                    style={window.mobilecheck() ? { paddingRight: 8, paddingLeft: 8 } : null}
                  >
                    <Suspense
                      fallback={
                        <div className="mt-6">
                          <FullWidthSpinner />
                        </div>
                      }
                    >
                      {window.mobilecheck()
                        ? routesMobile.map((route, index) =>
                            route.private ? (
                              <Switch key={index}>
                                <PrivateRoute exact {...route} />
                              </Switch>
                            ) : (
                              <Route key={index} exact {...route} />
                            )
                          )
                        : routes.map((route, index) =>
                            route.private ? (
                              <Switch key={index}>
                                <PrivateRoute exact {...route} />
                              </Switch>
                            ) : (
                              <Route key={index} exact {...route} />
                            )
                          )}
                    </Suspense>
                    {!window.mobilecheck() ? <Footer /> : null}
                  </div>
                </>
              </Router>
            </MuiPickersUtilsProvider>
          </Provider>
        </MuiThemeProvider>
      </>
    );
  }
}

export default App;
