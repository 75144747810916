import {
  GET_EVENTS,
  GET_EVENTS_NEXT_WEEK,
  GET_PATIENT_EVENTS,
  ADD_EVENT,
  ADD_PATIENT_EVENT,
  EDIT_EVENT,
  EVENTS_LOADING,
  DELETE_EVENT,
  // SET_EVENT_DONE
} from '../actions/types';

const initialState = {
  patients: [],
  patient: [],
  weekEvents: [],
  eventDate: new Date(),
  loading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        patients: action.payload,
        eventDate: action.payload.start,
        loading: false,
      };
    case GET_EVENTS_NEXT_WEEK:
      return {
        ...state,
        weekEvents: action.payload,
        loading: false,
      };
    case GET_PATIENT_EVENTS:
      return {
        ...state,
        patient: action.payload,
        eventDate: action.payload.start,
        loading: false,
      };
    case ADD_EVENT:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        eventDate: action.payload.start,
        loading: false,
      };
    case ADD_PATIENT_EVENT:
      return {
        ...state,
        patient: [action.payload, ...state.patient],
        loading: false,
      };
    case EDIT_EVENT:
      return {
        ...state,
        patients: [
          action.payload,
          ...state.patients.filter(event => {
            return event._id !== action.payload._id;
          }),
        ],
        loading: false,
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EVENT:
      return {
        ...state,
        patients: [
          ...state.patients.filter(event => {
            return event._id !== action.payload._id;
          }),
        ],
      };
    default:
      return state;
  }
}
