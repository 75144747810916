import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import PropTypes from 'prop-types';
import { DropdownIndicator, OptionCreate } from '../common';
import customStyles from '../common/customStyles';
import { TextField } from '@material-ui/core';

const SelectCreatableTreatment = ({ show, patient, info }) => {
  return show ? (
    <>
      <CreatableSelect
        components={{
          DropdownIndicator,
          Option: OptionCreate,
        }}
        styles={customStyles}
        fullWidth
        isClearable
        maxMenuHeight="125"
        className="mb-3"
        isValidNewOption={inputValue => {
          if (patient !== null && inputValue.length > 0) {
            return true;
          }

          return false;
        }}
        formatCreateLabel={inputValue => {
          return `Criar nova ferida - ${inputValue}`;
        }}
        onChange={info.onChange}
        onCreateOption={info.onCreate}
        placeholder="Associar registo de penso a ferida"
        options={info.options}
        value={info.value}
        noOptionsMessage={() => {
          if (patient !== null) {
            return 'Utente sem feridas';
          }

          return 'Selecione um utente';
        }}
      />
      {info.novoTratamento ? (
        <TextField
          fullWidth
          className="mb-3"
          label={`Avaliação inicial - ${info.value.label}`}
          value={info.descricaoFerida}
          multiline
          rows="3"
          variant="outlined"
          name="descricaoFerida"
          onChange={info.onChangeDescricaoFerida}
        />
      ) : null}
    </>
  ) : null;
};

SelectCreatableTreatment.defaultProps = {
  info: {},
  patient: null,
};

SelectCreatableTreatment.propTypes = {
  show: PropTypes.bool.isRequired,
  info: PropTypes.object,
  patient: PropTypes.object,
};

export default SelectCreatableTreatment;
