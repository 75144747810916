import React from 'react';
import { components } from 'react-select';
import { KeyboardArrowDownRounded } from '@material-ui/icons';

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDownRounded />
      </components.DropdownIndicator>
    )
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <img
      className="rounded-circle"
      alt={props.data.shortName}
      style={{ width: '30px', height: '30px' }}
      src={`/images/avatar/${props.data.avatar.thumb2.filename}`}
    />{' '}
    {props.data.shortName} - #{props.data.number}
  </components.SingleValue>
);

export const Option = props => {
  return (
    components.Option && (
      <components.Option {...props}>
        <img
          className="rounded-circle"
          alt={props.data.shortName}
          style={{ width: '30px', height: '30px' }}
          src={`/images/avatar/${props.data.avatar.thumb2.filename}`}
        />{' '}
        {props.data.shortName} - #{props.data.number}
      </components.Option>
    )
  );
};

export const OptionSelectType = props => {
  return (
    components.Option && (
      <components.Option {...props} style={{ backgroundColor: props.data.color }}>
        {props.data.label}
      </components.Option>
    )
  );
};

export const OptionCreate = props => {
  return components.Option && <components.Option {...props}>{props.data.label}</components.Option>;
};

export const OptionSingle = props => {
  return components.Option && <components.Option {...props}>{props.data.label}</components.Option>;
};

export const customStyles = {
  control: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    height: 'auto',
    boxShadow: 'none',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  }),
  singleValue: base => ({
    ...base,
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  }),
  option: (base, { isDisabled, isSelected }) => ({
    ...base,
    color: isDisabled ? '#ccc' : isSelected ? '#fff' : '#000',
  }),
  clearIndicator: base => ({
    ...base,
    cursor: 'pointer',
  }),
  dropdownIndicator: base => ({
    ...base,
    cursor: 'pointer',
  }),
};
