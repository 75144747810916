import {
  REGISTER_USER,
  SET_CURRENT_USER,
  CLEAR_ERRORS,
  GET_ERRORS,
  GET_USER_INFO,
  UPDATE_PROFILE_PIC,
  GET_INFERIORES,
  SET_CURRENT_USER_PERMISSIONS,
  UPDATE_PERMISSIONS_USER_PERMISSIONS,
} from './types';
import setAuthToken from '../../utils/setAuthToken';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import toast from '../../utils/toast';
import { getConfig } from './configActions';

// Register User
export const registerUser = userData => dispatch => {
  dispatch(clearErrors());

  axios
    .post('/api/users/register', userData)
    .then(res => {
      dispatch({
        type: REGISTER_USER,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login User
export const loginUser = loginData => dispatch => {
  dispatch(clearErrors());

  axios
    .post('/api/users/login', loginData)
    .then(res => {
      // Save token to sessionStorage
      const { token } = res.data;
      sessionStorage.setItem('jwtToken', token);

      // Set token to auth header
      setAuthToken(token);

      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update user profile pic
export const updateProfilePic = foto => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .put('/api/users/personal-avatar', foto, config)
    .then(res => {
      dispatch({
        type: UPDATE_PROFILE_PIC,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Recover password
export const recoverPassword = email => dispatch => {
  dispatch(clearErrors());

  axios
    .put('/api/users/recover', { email })
    .then(() => {
      toast({
        type: 'success',
        text: 'Verifique o seu email para recuperar a palavra-passe.',
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Confirm user registration
export const confirmRegistration = (info, history) => dispatch => {
  dispatch(clearErrors());

  axios
    .put('/api/users/confirm-user', info)
    .then(() => {
      history.push('/clinica/entrar');
      toast({
        type: 'success',
        text: 'Registo efetuado com sucesso.',
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Change password
export const changePassword = (info, history) => dispatch => {
  dispatch(clearErrors());

  axios
    .put('/api/users/change-password', info)
    .then(() => {
      history.push('/clinica/entrar');
      toast({
        type: 'success',
        text: 'Palavra-passe alterada com sucesso.',
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUserPermissions = (user_id, info) => dispatch => {
  axios
    .put(`/api/users/${user_id}/permissions`, info)
    .then(res => {
      dispatch({
        type: UPDATE_PERMISSIONS_USER_PERMISSIONS,
        payload: res.data,
      });
      dispatch(getInferiores());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set user permissions
export const setCurrentUserPermissions = info => {
  return {
    type: SET_CURRENT_USER_PERMISSIONS,
    payload: info,
  };
};

// Set current user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Logout User
export const logoutUser = () => dispatch => {
  sessionStorage.removeItem('jwtToken');

  setAuthToken(false);
  dispatch(getConfig());
  dispatch(setCurrentUser({}));
};

// Get User Profile
export const getUserInfo = username => dispatch => {
  axios
    .get(`/api/users/${username}`)
    .then(res => {
      dispatch({
        type: GET_USER_INFO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get User Profile
export const getInferiores = () => dispatch => {
  axios
    .get('/api/users/permissions')
    .then(res => {
      dispatch({
        type: GET_INFERIORES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
