const customStyles = {
  control: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    height: 'auto',
    boxShadow: 'none',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    }
  }),
  singleValue: base => ({
    ...base,
    '&:hover': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    }
  }),
  option: (base, { isDisabled, isSelected }) => ({
    ...base,
    color: isDisabled ? '#ccc' : isSelected ? '#fff' : '#000'
  }),
  clearIndicator: base => ({
    ...base,
    cursor: 'pointer'
  }),
  dropdownIndicator: base => ({
    ...base,
    cursor: 'pointer'
  })
};

export default customStyles;
