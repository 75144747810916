import {
  GET_PATIENT,
  ADD_PATIENT_TREATMENTS,
  ADD_PATIENT_HISTORY_TREATMENTS,
  PATIENT_LOADING,
  CLEAR_PATIENT_INFO,
  GET_PATIENT_NURSING_RECS,
  GET_PATIENT_MED_RECS,
  GET_PATIENT_BAND_RECS,
  GET_PATIENT_FALL_RECS,
  GET_PATIENT_VITALS_RECS,
  GET_PATIENT_SOCIAL_RECS,
  GET_PATIENT_NUTRI_RECS,
  GET_PATIENT_MED_NORMAL,
  GET_PATIENT_MED_SOS,
  GET_PATIENT_MED_HISTORICO,
  PATIENT_PAUSE_MED_NORMAL,
  PATIENT_PLAY_MED_NORMAL,
  PATIENT_PLAY_MED_SOS,
  PATIENT_CHANGE_MED_NORMAL,
  GET_MEDICATION_INFO,
  MEDICATION_INFO_LOADING,
  REGISTOS_LOADING,
  DELETE_SUSPENDED_MEDICATION,
  DELETE_MEDICATION_SOS,
  ADD_PATIENT_MEDICATION,
  STATS_LOADING,
  GET_PATIENT_INFO_STATS,
  UPDATE_PATIENT_STATUS,
  GET_PATIENT_FILES,
  PATIENT_LOADING_FILES,
  ADD_PATIENT_FILE,
  ADD_PATIENT_OCCUR,
  DELETE_PATIENT_FILE,
  GET_PATIENT_SCALES,
  END_PATIENT_TREATMENT,
  SCALES_LOADING,
  TREATMENTS_LOADING,
  UPDATE_CLINICAL_NOTE,
  UPDATE_SPECIFIC_CARE,
  ADD_PATIENT_PIC,
  LOAD_PATIENT_PICS,
  PICS_LOADING,
  LOAD_PATIENT_PDIS,
  PDIS_LOADING,
  SET_CURRENT_PDI,
  CLEAR_CURRENT_PDI,
  DELETE_PIC,
  DELETE_PDI,
  GET_PATIENT_RECORDS,
  GET_MOBILE_MODAL,
  SHOW_MOBILE_MODAL,
  CLOSE_MOBILE_MODAL,
  CLEAN_MED,
} from '../actions/types';

const initialState = {
  patient: {},
  treatments: [],
  historyTreatments: [],
  enfermagem: [],
  medicos: [],
  sinaisVitais: [],
  pensos: [],
  quedas: [],
  social: [],
  nutri: [],
  medicacaoSOS: [],
  medicacaoNormal: {
    ativa: [],
    suspensa: [],
  },
  historicoMedicacao: {
    normal: [],
    sos: [],
  },
  infoMedicacao: {},
  docsPessoais: [],
  pdis: {
    done: [],
    undone: [],
    current: null,
  },
  pics: [],
  infoStats: [],
  notaClinica: [],
  fichas: [],
  escalas: {
    braden: [],
    morse: [],
  },
  mobileModals: {
    enfermagem: false,
    medico: false,
    pensos: false,
    quedas: false,
    sinais: false,
    nutri: false,
  },
  showMobileModals: false,
  loading: false,
  loadingRegistos: false,
  loadingStats: false,
  loadingMedicacao: false,
  loadingDocs: false,
  loadingTreatments: false,
  loadingScales: false,
  loadingPics: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENT:
      return {
        ...state,
        patient: action.payload,
        loading: false,
      };
    case ADD_PATIENT_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
        loading: false,
        loadingTreatments: false,
      };
    case ADD_PATIENT_HISTORY_TREATMENTS:
      return {
        ...state,
        historyTreatments: action.payload,
        loading: false,
        loadingTreatments: false,
      };
    case GET_PATIENT_NURSING_RECS:
      return {
        ...state,
        enfermagem: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_MED_RECS:
      return {
        ...state,
        medicos: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_BAND_RECS:
      return {
        ...state,
        pensos: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_FALL_RECS:
      return {
        ...state,
        quedas: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_VITALS_RECS:
      return {
        ...state,
        sinaisVitais: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_SOCIAL_RECS:
      return {
        ...state,
        social: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_NUTRI_RECS:
      return {
        ...state,
        nutri: [...action.payload],
        loadingRegistos: false,
      };
    case GET_PATIENT_MED_NORMAL:
      return {
        ...state,
        medicacaoNormal: action.payload,
        loadingRegistos: false,
      };
    case GET_PATIENT_MED_SOS:
      return {
        ...state,
        medicacaoSOS: action.payload.ativa,
        loadingRegistos: false,
      };
    case GET_PATIENT_MED_HISTORICO:
      return {
        ...state,
        historicoMedicacao: {
          ...state.historicoMedicacao,
          normal: [...action.payload.normal],
          sos: [...action.payload.sos],
        },
        loadingRegistos: false,
      };
    case PATIENT_PAUSE_MED_NORMAL:
      return {
        ...state,
        medicacaoNormal: {
          ...state.medicacaoNormal,
          ativa: [
            ...state.medicacaoNormal.ativa.filter(med => {
              return med._id !== action.payload.medPaused;
            }),
          ],
          suspensa: [action.payload.medSuspensa, ...state.medicacaoNormal.suspensa],
        },
        loadingRegistos: false,
      };
    case PATIENT_PLAY_MED_NORMAL:
      return {
        ...state,
        medicacaoNormal: {
          ...state.medicacaoNormal,
          suspensa: [
            ...state.medicacaoNormal.suspensa.filter(med => {
              return med._id !== action.payload._id;
            }),
          ],
          ativa: [action.payload, ...state.medicacaoNormal.ativa],
        },
        loadingRegistos: false,
      };
    case PATIENT_PLAY_MED_SOS:
      return { ...state, loadingRegistos: false, loadingMedicacao: false };
    case GET_MEDICATION_INFO:
      return {
        ...state,
        infoMedicacao: action.payload,
        loadingMedicacao: false,
      };
    case DELETE_SUSPENDED_MEDICATION:
      return {
        ...state,
        medicacaoNormal: {
          ...state.medicacaoNormal,
          suspensa: [
            ...state.medicacaoNormal.suspensa.filter(med => {
              return med._id !== action.payload.medDeleted;
            }),
          ],
        },
        loadingMedicacao: false,
      };
    case GET_PATIENT_FILES:
      return {
        ...state,
        docsPessoais: action.payload,
        loadingDocs: false,
      };
    case ADD_PATIENT_FILE:
      return {
        ...state,
        docsPessoais: [...action.payload, ...state.docsPessoais],
        loadingDocs: false,
      };
    case DELETE_PATIENT_FILE:
      return {
        ...state,
        docsPessoais: [
          ...state.docsPessoais.filter(file => {
            return file.file_id._id !== action.payload.file_id;
          }),
        ],
        loadingDocs: false,
      };
    case DELETE_MEDICATION_SOS:
      return {
        ...state,
        medicacaoSOS: [
          ...state.medicacaoSOS.filter(med => {
            return med._id !== action.payload._id;
          }),
        ],
        loadingMedicacao: false,
      };
    case PATIENT_CHANGE_MED_NORMAL:
      return {
        ...state,
        medicacaoNormal: {
          ...state.medicacaoNormal,
          ativa: [
            action.payload,
            ...state.medicacaoNormal.ativa.filter(med => {
              return med._id !== action.payload._id;
            }),
          ],
        },
        loadingMedicacao: false,
        loadingRegistos: false,
      };
    case ADD_PATIENT_MEDICATION:
      return action.payload.type === 'normal'
        ? action.payload.paused
          ? {
              ...state,
              medicacaoNormal: {
                ...state.medicacaoNormal,
                suspensa: [action.payload, ...state.medicacaoNormal.suspensa],
              },
              loadingMedicacao: false,
              loadingRegistos: false,
            }
          : {
              ...state,
              medicacaoNormal: {
                ...state.medicacaoNormal,
                ativa: [action.payload, ...state.medicacaoNormal.ativa],
              },
              loadingMedicacao: false,
              loadingRegistos: false,
            }
        : {
            ...state,
            medicacaoSOS: [action.payload, ...state.medicacaoSOS],
            loadingMedicacao: false,
            loadingRegistos: false,
          };
    case ADD_PATIENT_OCCUR:
      return action.payload.type === 'nursing_rec'
        ? {
            ...state,
            enfermagem: [action.payload, ...state.enfermagem],
            loadingRegistos: false,
          }
        : action.payload.type === 'band_rec'
        ? {
            ...state,
            pensos: [action.payload, ...state.pensos],
            loadingRegistos: false,
          }
        : action.payload.type === 'med_rec'
        ? {
            ...state,
            medicos: [action.payload, ...state.medicos],
            loadingRegistos: false,
          }
        : action.payload.type === 'vitals_rec'
        ? {
            ...state,
            sinaisVitais: [action.payload, ...state.sinaisVitais],
            loadingRegistos: false,
          }
        : action.payload.type === 'fall_rec'
        ? {
            ...state,
            quedas: [action.payload, ...state.quedas],
            loadingRegistos: false,
          }
        : action.payload.type === 'medSOS'
        ? {
            ...state,
            medicacaoSOS: [action.payload, ...state.medicacaoSOS],
            loadingRegistos: false,
          }
        : action.payload.type === 'social_rec'
        ? {
            ...state,
            social: [action.payload, ...state.social],
            loadingRegistos: false,
          }
        : action.payload.type === 'nutri_rec'
        ? {
            ...state,
            nutri: [action.payload, ...state.nutri],
            loadingRegistos: false,
          }
        : {
            ...state,
            loadingRegistos: false,
          };
    case END_PATIENT_TREATMENT:
      return {
        ...state,
        treatments: [
          ...state.treatments.filter(treatment => {
            return treatment._id !== action.payload._id;
          }),
        ],
        historyTreatments: [action.payload, ...state.historyTreatments],
      };
    case UPDATE_PATIENT_STATUS:
      return {
        ...state,
        patient: action.payload,
        loading: false,
      };
    case UPDATE_CLINICAL_NOTE:
      return {
        ...state,
        patient: {
          ...state.patient,
          NCExame: action.payload.exames,
          NCAntecedentes: action.payload.antecedentes,
          allergies: action.payload.alergias,
          notaClinicaLastUpdateUser: {
            ...state.patient.notaClinicaLastUpdateUser,
            name: action.payload.name,
          },
          notaClinicaLastUpdateDate: action.payload.date,
        },
      };
    case UPDATE_SPECIFIC_CARE:
      return {
        ...state,
        patient: {
          ...state.patient,
          specificCare: [
            { ...action.payload.specificCare, user_id: { name: action.payload.name } },
            ...state.patient.specificCare,
          ],
        },
      };
    case DELETE_PIC:
      return {
        ...state,
        pics: [...state.pics.filter(pic => pic._id !== action.payload.pic_id)],
      };
    case DELETE_PDI:
      return {
        ...state,
        pdis: {
          ...state.pdis,
          done: [...state.pdis.done.filter(pdi => pdi._id !== action.payload.pdi_id)],
        },
      };
    case GET_PATIENT_RECORDS:
      return {
        ...state,
        fichas: action.payload,
      };
    case CLEAR_PATIENT_INFO:
      return {
        ...state,
        patient: {},
        treatments: [],
        enfermagem: [],
        medicos: [],
        sinaisVitais: [],
        pensos: [],
        quedas: [],
        medicacaoSOS: [],
        medicacaoNormal: [],
        docsPessoais: [],
        pdis: {
          ...state.pdis,
          done: [],
          undone: [],
          current: null,
        },
        pics: [],
        escalas: {},
        notaClinica: [],
      };
    case GET_PATIENT_SCALES:
      return {
        ...state,
        escalas: {
          ...state.escalas,
          braden: action.payload.braden,
          morse: action.payload.morse,
        },
        loadingScales: false,
      };
    case LOAD_PATIENT_PICS:
      return {
        ...state,
        pics: action.payload,
        loadingPics: false,
      };
    case ADD_PATIENT_PIC:
      return {
        ...state,
        pics: [{ ...action.payload }, ...state.pics],
      };
    case LOAD_PATIENT_PDIS:
      return {
        ...state,
        pdis: {
          ...state.pdis,
          done: action.payload.done,
          undone: action.payload.undone,
        },
        loadingPdis: false,
      };
    case SET_CURRENT_PDI: {
      return {
        ...state,
        pdis: {
          ...state.pdis,
          current: action.payload,
        },
      };
    }
    case CLEAR_CURRENT_PDI: {
      return {
        ...state,
        pdis: {
          ...state.pdis,
          current: null,
        },
      };
    }
    case GET_MOBILE_MODAL:
      return {
        ...state,
        mobileModals: {
          ...state.mobileModals,
          enfermagem: action.payload === 'closeAll' ? false : action.payload === 'enfermagem',
          medico: action.payload === 'closeAll' ? false : action.payload === 'medico',
          pensos: action.payload === 'closeAll' ? false : action.payload === 'pensos',
          quedas: action.payload === 'closeAll' ? false : action.payload === 'quedas',
          sinais: action.payload === 'closeAll' ? false : action.payload === 'sinais',
          nutri: action.payload === 'closeAll' ? false : action.payload === 'nutri',
        },
      };

    case CLEAN_MED:
      return {
        ...state,
        medicacaoSOS: [],
        medicacaoNormal: {
          ...state.medicacaoNormal,
          ativa: [],
          suspensa: [],
        },
        loading: false,
      };
    case SHOW_MOBILE_MODAL:
      return {
        ...state,
        showMobileModals: true,
      };

    case CLOSE_MOBILE_MODAL:
      return {
        ...state,
        showMobileModals: false,
      };
    case PATIENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REGISTOS_LOADING:
      return {
        ...state,
        loadingRegistos: true,
      };
    case STATS_LOADING:
      return {
        ...state,
        loadingStats: true,
      };
    case PICS_LOADING:
      return {
        ...state,
        loadingPics: true,
      };
    case PDIS_LOADING:
      return {
        ...state,
        loadingPdis: true,
      };
    case PATIENT_LOADING_FILES:
      return {
        ...state,
        loadingDocs: true,
      };
    case MEDICATION_INFO_LOADING:
      return {
        ...state,
        loadingMedicacao: true,
      };
    case TREATMENTS_LOADING:
      return {
        ...state,
        loadingTreatments: true,
      };
    case SCALES_LOADING:
      return {
        ...state,
        loadingScales: true,
      };
    case GET_PATIENT_INFO_STATS:
      return {
        ...state,
        infoStats: action.payload,
        loadingStats: false,
      };
    default:
      return state;
  }
}
