import React from 'react';
import Grid from '@material-ui/core/Grid';
import Spinner from './Spinner';

const FullWidthSpinner = () => {
  return (
    <Grid item md={11} className="w-100 mx-auto">
      <div className="w-100 d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    </Grid>
  );
};

export default FullWidthSpinner;
