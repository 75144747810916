import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types';
import setAuthToken from '../../utils/setAuthToken';

const initialState = {};

export default function(state = initialState, action) {
  if (
    action.type === GET_ERRORS &&
    action.payload === 'Unauthorized' &&
    sessionStorage.getItem('jwtToken') !== null
  ) {
    sessionStorage.removeItem('jwtToken');

    setAuthToken(false);

    window.location.reload();
  }

  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
