import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const PasswordStep = ({ values, errors, onChange }) => {
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={errors.password ? true : false}
        helperText={errors ? errors.password : null}
        label="Palavra-passe"
        className="mt-4"
        disabled={values.step > 4 ? true : false}
        placeholder="Insira uma palavra-passe"
        value={values.password}
        onChange={onChange('password')}
        type="password"
      />
      <TextField
        fullWidth
        variant="outlined"
        error={errors.passwordCheck ? true : false}
        helperText={errors ? errors.passwordCheck : null}
        label="Confirmar palavra-passe"
        className="mt-4"
        disabled={values.step > 4 ? true : false}
        placeholder="Confirme a palavra-passe"
        value={values.passwordCheck}
        onChange={onChange('passwordCheck')}
        type="password"
      />
    </>
  );
};

PasswordStep.defaultProps = {
  errors: {},
};

PasswordStep.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PasswordStep;
