import { GET_OCCURS, OCCURS_LOADING, ADD_OCCUR } from '../actions/types';

const initialState = {
  patients: [],
  patient: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OCCURS:
      return {
        ...state,
        patients: action.payload,
        loading: false
      };
    case ADD_OCCUR:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        loading: false
      };
    case OCCURS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
