import React from 'react';
import PropTypes from 'prop-types';
import TextFieldSinal from './TextFieldSinal';

const SinaisVitais = ({ show, info }) => {
  return show ? (
    <div className="row d-flex justify-content-center mb-3">
      {info.map((sinal, index) => (
        <div key={index} className="m-1 w-100 text-center">
          <TextFieldSinal info={sinal} />
        </div>
      ))}
    </div>
  ) : null;
};

SinaisVitais.defaultProps = {
  info: [],
};

SinaisVitais.propTypes = {
  show: PropTypes.bool.isRequired,
  info: PropTypes.array,
};

export default SinaisVitais;
