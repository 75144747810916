import {
  GET_PATIENTS,
  ADD_PATIENT,
  GET_ERRORS,
  GET_PATIENTS_LAR,
  PATIENTS_LOADING,
  GET_PATIENTS_CENTRO_DIA,
  GET_PATIENTS_ARQUIVO,
} from './types';
import axios from 'axios';
import { getPatientInfo, getPatientRecords } from './patientActions';

// Get all patients
export const loadPatients = () => dispatch => {
  dispatch(setPatientsLoading());

  axios
    .get('/api/patients')
    .then(res => {
      dispatch({
        type: GET_PATIENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patients 'lar'
export const getUtentesLar = () => dispatch => {
  dispatch(setPatientsLoading());

  axios
    .get('/api/patients/ri')
    .then(res => {
      dispatch({
        type: GET_PATIENTS_LAR,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patients 'centro-de-dia'
export const getUtentesCentro = () => dispatch => {
  dispatch(setPatientsLoading());

  axios
    .get('/api/patients/cd')
    .then(res => {
      dispatch({
        type: GET_PATIENTS_CENTRO_DIA,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patients 'arquivo'
export const getUtentesArquivo = () => dispatch => {
  dispatch(setPatientsLoading());

  axios
    .get('/api/patients/arq')
    .then(res => {
      dispatch({
        type: GET_PATIENTS_ARQUIVO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add patient
export const addNewPatient = (formData, history) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .post('/api/patients', formData, config)
    .then(res => {
      history.push('/clinica');
      dispatch({
        type: ADD_PATIENT,
        payload: res.data,
      });
    })
    .catch(err => {
      // Caso haja erro, é no topo da página
      window.scrollTo(0, 0);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update patient
export const updatePatient = (formData, history, patientId) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .put(`/api/patients/patient/${patientId}/patient-record`, formData, config)
    .then(res => {
      history.push(`/clinica/utente/${res.data.url_name}`);
      dispatch(loadPatients());
      dispatch(getPatientInfo(res.data.url_name));
      dispatch(getPatientRecords(res.data._id));
      window.scrollTo(0, 0);
    })
    .catch(err => {
      // Caso haja erro, é no topo da página
      window.scrollTo(0, 0);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set loading flag
export const setPatientsLoading = () => {
  return {
    type: PATIENTS_LOADING,
  };
};
