import {
  ADD_PATIENT,
  GET_PATIENTS,
  GET_PATIENTS_LAR,
  GET_PATIENTS_CENTRO_DIA,
  GET_PATIENTS_ARQUIVO,
  ADD_PATIENT_TREATMENTS,
  PATIENTS_LOADING,
} from '../actions/types';

const initialState = {
  patients: [],
  lar: [],
  centroDia: [],
  arq: [],
  patient: {},
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_PATIENT:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        loading: false,
      };
    case GET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
        loading: false,
      };
    case GET_PATIENTS_LAR:
      return {
        ...state,
        lar: action.payload,
        loading: false,
      };
    case GET_PATIENTS_CENTRO_DIA:
      return {
        ...state,
        centroDia: action.payload,
        loading: false,
      };
    case GET_PATIENTS_ARQUIVO:
      return {
        ...state,
        arq: action.payload,
        loading: false,
      };
    case ADD_PATIENT_TREATMENTS:
      return {
        ...state,
        patient: {
          ...state.patient,
          treatments: action.payload,
        },
        loading: false,
      };
    case PATIENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
