const config = {
  name: 'Centro Social de Calendário',
  shortName: 'Lar Calendário',
  email: 'geral@cscalendario.com',
  phone: '252 376 071',
  fax: '252 312 456',
  address: 'Rua de S. Julião nº 720',
  postalCode: '4760-384 Calendário',
  website: 'www.cscalendario.com',
};

export default config;
