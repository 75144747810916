import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { loginUser } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import getGreetingTime from '../../utils/getGreetingTime';
import toast from '../../utils/toast';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../utils/theme/styles';

export class LoginForm extends Component {
  state = {
    username: '',
    password: '',
    submit: false,
    errors: {},
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated && this.props.auth.user.clinica) {
      toast({
        type: 'warning',
        text: 'Já está com sessão iniciada',
      });
      this.props.history.push('/clinica');
    }
  }

  componentDidUpdate(prevProps) {
    const { submit } = this.state;
    if (this.props.auth.user.clinica) {
      toast({
        type: 'success',
        text: `${getGreetingTime()} ${this.props.auth.user.name}.`,
      });
      return this.props.history.push('/clinica');
    }

    if (submit && this.props.auth.isAuthenticated && !this.props.auth.user.clinica) {
      this.setState({
        submit: false,
      });

      return toast.fire({
        type: 'warning',
        text: 'Sem permissões para aceder à Plataforma Clínica',
      });
    }

    if (this.props.errors !== prevProps.errors) {
      if (this.props.errors === 'Unauthorized') {
        return toast({
          type: 'error',
          text: 'A sua sessão terminou, efetue login novamente',
        });
      }
      return this.setState({
        errors: this.props.errors,
      });
    }

    return true;
  }

  onChange = input => e => this.setState({ [input]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const { username, password } = this.state;

    const loginData = { username, password };

    this.setState({
      submit: true,
    });

    this.props.loginUser(loginData);
  };

  render() {
    const { username, password, errors } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Grid container justify="center" className="mt-6 content">
          <Grid container spacing={16} justify="center">
            <Grid item md={4}>
              <img alt="csclogo" className="w-100" src="/images/wiseLogo.png" />
            </Grid>
          </Grid>
          <Paper className="p-20" style={{ marginTop: 50 }} width="30%">
            <Grid
              container
              className="mb-5"
              spacing={16}
              style={{ marginTop: 50 }}
              justify="center"
            >
              <Grid item md={6} className="mx-auto">
                <img alt="csclogo" className="w-100" src="/images/logo.png" />
              </Grid>
            </Grid>
            <Grid container spacing={16} justify="center">
              <form noValidate onSubmit={this.onSubmit}>
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors.username ? true : false}
                  helperText={errors ? errors.username : null}
                  label="Nome de utilizador"
                  className="mb-2"
                  value={username}
                  onChange={this.onChange('username')}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors.password ? true : false}
                  helperText={errors ? errors.password : null}
                  label="Password"
                  className="mb-2 mt-2"
                  placeholder="Insira a sua password"
                  value={password}
                  onChange={this.onChange('password')}
                  type="password"
                />
              </form>
            </Grid>
            <Grid container className="mt-3" spacing={16} justify="space-around">
              <Button onClick={this.onSubmit} className="mt-2" variant="contained" color="primary">
                Iniciar sessão
              </Button>
              {errors.password ? (
                <Button
                  className={`mt-2 ${classes.textWiseGrey}`}
                  variant="text"
                  href="/clinica/recuperar"
                >
                  Recuperar palavra-passe
                </Button>
              ) : null}
              <Button
                className={`mt-2 ${classes.textWiseGrey}`}
                variant="text"
                href="/clinica/registar"
              >
                Registar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </>
    );
  }
}

LoginForm.defaultProps = {
  errors: null,
};

LoginForm.propTypes = {
  history: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withStyles(styles)(LoginForm));
