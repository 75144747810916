const styles = theme => ({
  wiseBlueButton: {
    backgroundColor: theme.palette.piedpiper[300],
    color: '#fff',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.piedpiper[300],
    },
  },
  iconButtonPrimary: {
    color: theme.palette.piedpiper[500],
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.piedpiper[300],
    },
  },
  dashName: {
    color: theme.palette.piedpiper[700],
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      color: theme.palette.piedpiper[900],
    },
  },
  textMedSOS: {
    color: theme.palette.wiseRed[500],
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      color: theme.palette.piedpiper[700],
    },
  },
  dashUserName: {
    fontSize: 14,
    fontWeight: 450,
    color: theme.palette.piedpiper[700],
    '&:hover': {
      color: theme.palette.piedpiper[900],
    },
  },
  linkName: {
    fontWeight: 400,
    color: theme.palette.wiseBlue[600],
    '&:hover': {
      color: theme.palette.wiseBlue[900],
      textDecoration: 'underline',
    },
  },
  breadCrumbTitle: {
    color: theme.palette.wiseBlack[600],
    '&:hover': {
      color: theme.palette.wiseBlue[900],
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  breadCrumbTitleDisable: {
    color: theme.palette.wiseBlack[600],
  },
  dashIcon: {
    color: theme.palette.wiseGrey[500],
    '&:hover': {
      color: theme.palette.wiseGrey[900],
    },
  },
  titlePrimary: {
    color: theme.palette.piedpiper[700],
    fontWeight: 300,
  },
  cardTitle: {
    color: theme.palette.piedpiper[400],
    fontWeight: 400,
    fontSize: 18,
  },
  formTitle: {
    color: theme.palette.piedpiper[500],
    fontWeight: 400,
    fontSize: 22,
  },
  formTitleBig: {
    color: theme.palette.piedpiper[500],
    fontWeight: 500,
    fontSize: 26,
  },
  formSubTitle: {
    color: theme.palette.piedpiper[400],
    fontWeight: 400,
    fontSize: 16,
  },
  cardTitleBig: {
    color: theme.palette.piedpiper[400],
    fontWeight: 400,
    fontSize: '1.4rem',
  },
  eventTitle: {
    color: theme.palette.piedpiper[700],
    fontSize: 11,
  },
  eventTitleDisabled: {
    color: theme.palette.wiseGrey[500],
    fontSize: 11,
  },
  cardTitleText: {
    color: theme.palette.piedpiper[700],
    fontSize: 12,
  },
  cardText: {
    color: theme.palette.wiseGrey[700],
    fontSize: 14,
  },
  textWiseBlack: {
    color: theme.palette.wiseBlack[500],
  },
  textWiseGrey: {
    color: theme.palette.wiseGrey[600],
    '&:hover': {
      color: theme.palette.wiseGrey[700],
    },
    // fontWeight: 300,
  },
  textWiseLabel: {
    color: theme.palette.wiseGrey[700],
    fontWeight: 500,
    fontSize: 12,
  },
  bgWiseRed: {
    backgroundColor: theme.palette.wiseRed[500],
    '&:hover': {
      backgroundColor: theme.palette.wiseRed[900],
    },
  },
  bgOnlyPiedPiper: {
    backgroundColor: theme.palette.piedpiper[500],
  },
  bgWiseOrange: {
    backgroundColor: theme.palette.wiseOrange[500],
    '&:hover': {
      backgroundColor: theme.palette.wiseOrange[700],
    },
  },
  bgWiseGreen: {
    backgroundColor: theme.palette.wiseGreen[500],
    '&:hover': {
      backgroundColor: theme.palette.wiseGreen[900],
    },
  },
  bgWiseGrey: {
    backgroundColor: theme.palette.wiseGrey[500],
    '&:hover': {
      backgroundColor: theme.palette.wiseGrey[900],
    },
  },
  bgPiedPiper: {
    backgroundColor: theme.palette.piedpiper[400],
    '&:hover': {
      backgroundColor: theme.palette.piedpiper[700],
    },
  },
  bgPiedPiperHoover: {
    '&:hover': {
      backgroundColor: theme.palette.piedpiper[300],
    },
  },
  colorWiseBlue: {
    color: theme.palette.wiseBlue[500],
  },
  colorWiseRed: {
    color: theme.palette.wiseRed[500],
    '&:disabled': {
      backgroundColor: theme.palette.wiseRed[200],
      opacity: 0.8,
    },
  },
  colorWiseGreen: {
    color: theme.palette.wiseGreen[500],
    '&:disabled': {
      display: 'none',
    },
  },
  buttonBgWiseRed: {
    backgroundColor: theme.palette.wiseRed[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.wiseRed[700],
    },
  },
  buttonBgWiseGreen: {
    backgroundColor: theme.palette.wiseGreen[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.wiseGreen[900],
    },
  },
  textWiseDanger: { color: theme.palette.wiseRed[400], fontWeight: 500 },
  textWiseDangerGrey: { color: theme.palette.wiseGrey[600], fontWeight: 500 },
  inputCSS: {
    borderRadius: 15,
  },
  iconColorWiseRed: {
    color: theme.palette.wiseRed[500],
    '&:hover': {
      backgroundColor: theme.palette.wiseRed[50],
    },
  },
  secondNavIcon: {
    border: 'none',
    boxShadow: ' 0px 8px 15px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease 0s',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: ' 0px 15px 20px #e0efed',
      color: '#fff',
      transform: 'translateY(-7px)',
    },
  },
  secondNavIcon2: {
    border: 'none',
    transition: 'all 0.3s ease 0s',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      boxShadow: ' 0px 15px 20px #e0efed',
      color: '#fff',
    },
  },
  secondNavIconActive: {
    backgroundColor: 'white',
    boxShadow: ' 0px 15px 20px #e0efed',
    color: '#fff',
    transform: 'translateY(-7px)',
  },
  dialogSpeedDial: {
    margin: '40px',
    backgroundColor: 'transparent!important',
    boxShadow: null,
  },
  speedDialogIcon: {
    boxShadow: ' 0px 8px 15px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.6)',
    borderRadius: '50px',
  },
  speedDialogLabel: {
    padding: '8px',
    color: 'white',
    background: 'rgba(76, 76, 76, 0.9)',
    borderRadius: '50px',
  },
  mobileNameBar: {
    color: 'white',
    fontWeight: 500,
    fontSize: 16,
  },
  showAppBar: {
    flexGrow: 1,
    transform: 'translate(0, 0)',
    transition: 'transform .5s',
  },
  hideAppBarTop: {
    flexGrow: 1,
    transform: 'translate(0, -70px)',
    transition: 'transform .5s',
  },
  hideAppBarDown: {
    flexGrow: 1,
    transform: 'translate(0, 70px)',
    transition: 'transform .5s',
  },
  showTabs: {
    flexGrow: 1,
    transform: 'translate(0,0)',
    transition: 'transform .5s',
  },
  hideTabs: {
    flexGrow: 1,
    transform: 'translate(0, -3.5rem)',
    transition: 'transform .5s',
  },
  medSingleCard: {
    border: '0.0625rem solid #80bbb2',
    borderRadius: '10px',
    background: '#e8f4f2',
  },
  medSingleCardDisabled: {
    border: '0.0625rem solid #9da1a5',
    borderRadius: '10px',
    background: '#eeeff0',
  },
});

export default styles;
