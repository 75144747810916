import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Footer = ({ config, auth }) => {
  return (
    auth.isAuthenticated && (
      <footer style={{ padding: '30px 0', backgroundColor: '#fff', marginTop: '5rem' }}>
        <Typography variant="subtitle2" align="center" gutterBottom>
          &copy; {new Date().getFullYear()} wiseCare - {config.name}
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Desenvolvido por{' '}
          <Link
            to="https://www.codeseam.pt"
            style={{ color: 'rgba(0, 0, 0, 0.54)', textDecoration: 'none' }}
          >
            CodeSeam Software
          </Link>
        </Typography>
      </footer>
    )
  );
};

Footer.propTypes = {
  config: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  config: state.config,
  auth: state.auth,
});

export default connect(mapStateToProps)(Footer);
