/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import { Card, Dialog, DialogContent } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from '../../store/actions/modalsActions';
import { addOccur, addBandRec, getPatientTreatments } from '../../store/actions/occursActions';
import ModalHeader from './modals/ModalHeader';
import ModalDatePicker from './modals/ModalDatePicker';
import ModalSelect from './modals/ModalSelect';
import TextFieldNormal from './modals/TextFieldNormal';
import TextFieldFile from './modals/TextFieldFile';
import ModalFooter from './modals/ModalFooter';
import SelectCreatableTreatment from './modals/SelectCreatableTreatment';
import SinaisVitais from './modals/SinaisVitais';
import toast from '../../utils/toast';

moment.locale('pt');

export class Modals extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = {
    patient: null,
    treatment: null,
    modalEnfermagemData: {
      observacoes: '',
    },
    modalMedicoData: {
      observacoes: '',
    },
    modalSinaisVitaisData: {
      observacoes: '',
      tas: '',
      tad: '',
      pulse: '',
      spo2: '',
      temp: '',
    },
    modalPensosData: {
      descricao: '',
      tratamento: '',
      ferida: null,
      novoTratamento: false,
      descricaoFerida: '',
    },
    modalQuedasData: {
      descricao: '',
      sequelas: '',
    },
    modalSocialData: {
      observacoes: '',
    },
    modalNutriData: {
      observacoes: '',
    },
    fotoTratamento: '',
    errors: {},
    dataModal: moment()
      .utc()
      .toISOString(),
  };

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      if (this.props.errors.occur) {
        toast({
          type: 'error',
          text: this.props.errors.occur,
        });
      }
    }
  }

  handleDateChange = (input, date) => {
    this.setState({
      [input]: moment(date)
        .utc()
        .toISOString(),
    });
  };

  onChangePatient = e => {
    this.setState({ patient: e });
    this.props.getPatientTreatments(e._id);
  };

  // Modal pensos
  onChangeCreatable = e => {
    this.setState(prevState => ({
      modalPensosData: {
        ...prevState.modalPensosData,
        ferida: e,
        novoTratamento: false,
      },
    }));
  };

  onCreateLabel = input => {
    this.setState(prevState => ({
      modalPensosData: {
        ...prevState.modalPensosData,
        ferida: { label: input, value: input, new: true },
        novoTratamento: true,
      },
    }));
  };

  onChange = (modal, input) => e => {
    e.persist();
    switch (modal) {
      case 'modalEnfermagem':
        this.setState(prevState => ({
          modalEnfermagemData: {
            ...prevState.modalEnfermagemData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalMedico':
        this.setState(prevState => ({
          modalMedicoData: {
            ...prevState.modalMedicoData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalSinaisVitais':
        this.setState(prevState => ({
          modalSinaisVitaisData: {
            ...prevState.modalSinaisVitaisData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalPensos':
        this.setState(prevState => ({
          modalPensosData: {
            ...prevState.modalPensosData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalQuedas':
        this.setState(prevState => ({
          modalQuedasData: {
            ...prevState.modalQuedasData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalSocial':
        this.setState(prevState => ({
          modalSocialData: {
            ...prevState.modalSocialData,
            [input]: e.target.value,
          },
        }));
        break;
      case 'modalNutri':
        this.setState(prevState => ({
          modalNutriData: {
            ...prevState.modalNutriData,
            [input]: e.target.value,
          },
        }));
        break;
      default:
        break;
    }
  };

  // Modals
  handleCloseModal = modal => e => {
    e.preventDefault();
    return this.props.closeModal(modal);
  };

  handleSubmitModal = input => e => {
    e.preventDefault();
    const { patient, dataModal } = this.state;
    let occurData;
    if (patient === null) {
      return this.setState(prevState => ({
        errors: { ...prevState.errors, patient: 'Selecione um utente' },
      }));
    }

    let date = dataModal;

    if (moment(date).isDST()) {
      date = moment(date)
        .add(1, 'hour')
        .utc();
    }

    switch (input) {
      case 'modalEnfermagem':
        const { modalEnfermagemData } = this.state;
        occurData = {
          patient: patient._id,
          observacoes: modalEnfermagemData.observacoes,
          date,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalEnfermagemData: {
            observacoes: '',
          },
        }));
        this.props.closeModal('modalEnfermagem');
        return this.props.addOccur('nursing_rec', occurData);
      case 'modalMedico':
        const { modalMedicoData } = this.state;
        occurData = {
          patient: patient._id,
          observacoes: modalMedicoData.observacoes,
          date,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalMedicoData: {
            observacoes: '',
          },
        }));
        this.props.closeModal('modalMedico');
        return this.props.addOccur('med_rec', occurData);
      case 'modalSinaisVitais':
        const { modalSinaisVitaisData } = this.state;
        const { pulse, tas, tad, temp, spo2, observacoes } = modalSinaisVitaisData;

        occurData = {
          patient: patient._id,
          observacoes,
          date,
          pulse,
          tas,
          tad,
          temp,
          spo2,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalSinaisVitaisData: {
            observacoes: '',
            pulse: '',
            tas: '',
            tad: '',
            temp: '',
            spo2: '',
          },
        }));
        this.props.closeModal('modalSinaisVitais');
        return this.props.addOccur('vitals_rec', occurData);
      case 'modalPensos':
        const { modalPensosData, fotoTratamento } = this.state;
        const formData = new FormData();
        formData.append('patient', patient._id);
        formData.append('observacoes', modalPensosData.descricao);
        formData.append('date', date);
        formData.append('tratamento', modalPensosData.tratamento);
        Array.from(fotoTratamento).forEach(file => {
          formData.append('ficheiros', file);
        });
        if (modalPensosData.novoTratamento) {
          formData.append('ferida', modalPensosData.ferida.label);
          formData.append('descricaoFerida', modalPensosData.descricaoFerida);
        } else if (modalPensosData.ferida !== null) {
          formData.append('ferida', modalPensosData.ferida._id);
        }
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          treatment: null,
          fotoTratamento: '',
          modalPensosData: {
            descricao: '',
            tratamento: '',
            ferida: null,
            novoTratamento: false,
            descricaoFerida: '',
          },
        }));
        this.props.closeModal('modalPensos');
        return this.props.addBandRec(formData);
      case 'modalQuedas':
        const { modalQuedasData } = this.state;
        occurData = {
          patient: patient._id,
          observacoes: modalQuedasData.descricao,
          date,
          sequelas: modalQuedasData.sequelas,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalPensosData: {
            descricao: '',
            sequelas: '',
          },
        }));
        this.props.closeModal('modalQuedas');
        return this.props.addOccur('fall_rec', occurData);
      case 'modalSocial':
        const { modalSocialData } = this.state;
        occurData = {
          patient: patient._id,
          observacoes: modalSocialData.observacoes,
          date,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalSocialData: {
            observacoes: '',
          },
        }));
        this.props.closeModal('modalSocial');
        return this.props.addOccur('social_rec', occurData);
      case 'modalNutri':
        const { modalNutriData } = this.state;
        occurData = {
          patient: patient._id,
          observacoes: modalNutriData.observacoes,
          date,
        };
        this.setState(prevState => ({
          ...prevState,
          patient: null,
          dataModal: moment()
            .utc()
            .toISOString(),
          modalNutriData: {
            observacoes: '',
          },
        }));
        this.props.closeModal('modalNutri');
        return this.props.addOccur('nutri_rec', occurData);
      default:
        return true;
    }
  };

  handleChangeFoto = e => {
    e.preventDefault();
    this.setState({
      fotoTratamento: this.fileInput.current.files,
    });
  };

  render() {
    const {
      patient,
      dataModal,
      errors,
      modalEnfermagemData,
      modalMedicoData,
      modalSinaisVitaisData,
      modalPensosData,
      fotoTratamento,
      modalQuedasData,
      modalSocialData,
      modalNutriData,
    } = this.state;

    const { patients } = this.props.patients;

    const { treatments } = this.props;

    const {
      modalEnfermagem,
      modalMedico,
      modalSinaisVitais,
      modalPensos,
      modalQuedas,
      modalSocial,
      modalNutri,
    } = this.props.modals;

    let nomesFicheiros = '';

    if (fotoTratamento.length > 0) {
      Array.from(fotoTratamento).forEach(file => {
        nomesFicheiros += `${file.name}, `;
      });
      nomesFicheiros = nomesFicheiros.slice(0, -2);
    }

    const comum = {
      datePicker: true,
      errors,
      patients: true,
      selectedDate: dataModal,
      handleDateChange: this.handleDateChange,
      onClose: this.handleCloseModal,
      onSubmit: this.handleSubmitModal,
    };

    const modalsContent = [
      {
        ...comum,
        open: modalEnfermagem,
        modalImg: '/images/icons/registos/nurse-40.png',
        id: 'modalEnfermagem',
        title: 'Registo de Enfermagem',
        textfields: [
          {
            label: 'Observações',
            value: modalEnfermagemData.observacoes,
            onChange: this.onChange('modalEnfermagem', 'observacoes'),
            multiline: true,
          },
        ],
      },
      {
        ...comum,
        open: modalMedico,
        modalImg: '/images/icons/registos/medic-40.png',
        id: 'modalMedico',
        title: 'Registo Médico',
        textfields: [
          {
            label: 'Observações',
            value: modalMedicoData.observacoes,
            onChange: this.onChange('modalMedico', 'observacoes'),
            multiline: true,
          },
        ],
      },
      {
        ...comum,
        open: modalSinaisVitais,
        modalImg: '/images/icons/registos/heart-40.png',
        id: 'modalSinaisVitais',
        title: 'Registo de Sinais Vitais',
        textfields: [
          {
            label: 'Observações',
            value: modalSinaisVitaisData.observacoes,
            onChange: this.onChange('modalSinaisVitais', 'observacoes'),
            multiline: true,
          },
        ],
        sinaisVitais: [
          {
            label: 'T.A.S',
            small: 'mmHG',
            value: modalSinaisVitaisData.tas,
            onChange: this.onChange('modalSinaisVitais', 'tas'),
          },
          {
            label: 'T.A.D',
            small: 'mmHG',
            value: modalSinaisVitaisData.tad,
            onChange: this.onChange('modalSinaisVitais', 'tad'),
          },
          {
            label: 'Pulsação',
            small: 'p/m',
            value: modalSinaisVitaisData.pulse,
            onChange: this.onChange('modalSinaisVitais', 'pulse'),
          },
          {
            label: 'SpO2',
            small: '%',
            value: modalSinaisVitaisData.spo2,
            onChange: this.onChange('modalSinaisVitais', 'spo2'),
          },
          {
            label: 'Temp',
            small: 'ºC',
            value: modalSinaisVitaisData.temp,
            onChange: this.onChange('modalSinaisVitais', 'temp'),
          },
        ],
      },
      {
        ...comum,
        open: modalPensos,
        modalImg: '/images/icons/registos/band-40.png',
        id: 'modalPensos',
        title: 'Registo de Penso',
        textfields: [
          {
            label: 'Descrição',
            value: modalPensosData.descricao,
            onChange: this.onChange('modalPensos', 'descricao'),
            multiline: true,
          },
          {
            label: 'Tratamento aplicado',
            value: modalPensosData.tratamento,
            onChange: this.onChange('modalPensos', 'tratamento'),
            multiline: true,
          },
        ],
        creatableSelect: {
          onChange: this.onChangeCreatable,
          onCreate: this.onCreateLabel,
          options:
            treatments !== undefined
              ? treatments.map(treatment => {
                  return {
                    label: treatment.name,
                    value: treatment.name,
                    _id: treatment._id,
                  };
                })
              : [],
          value: modalPensosData.ferida,
          novoTratamento: modalPensosData.novoTratamento,
          onChangeDescricaoFerida: this.onChange('modalPensos', 'descricaoFerida'),
          descricaoFerida: modalPensosData.descricaoFerida,
        },
        addFile: true,
        fotoTratamento,
        changeFoto: this.handleChangeFoto,
      },
      {
        ...comum,
        open: modalQuedas,
        modalImg: '/images/icons/registos/fall-40.png',
        id: 'modalQuedas',
        title: 'Registo de Queda',
        textfields: [
          {
            label: 'Descrição',
            value: modalQuedasData.descricao,
            onChange: this.onChange('modalQuedas', 'descricao'),
            multiline: true,
          },
          {
            label: 'Sequelas',
            value: modalQuedasData.sequelas,
            onChange: this.onChange('modalQuedas', 'sequelas'),
            multiline: true,
          },
        ],
      },
      {
        ...comum,
        open: modalSocial,
        modalImg: '/images/icons/registos/social-40.png',
        id: 'modalSocial',
        title: 'Registo de Ação Social',
        textfields: [
          {
            label: 'Observações',
            value: modalSocialData.observacoes,
            onChange: this.onChange('modalSocial', 'observacoes'),
            multiline: true,
          },
        ],
      },
      {
        ...comum,
        open: modalNutri,
        modalImg: '/images/icons/registos/nutri-40.png',
        id: 'modalNutri',
        title: 'Registo de Nutrição',
        textfields: [
          {
            label: 'Observações',
            value: modalNutriData.observacoes,
            onChange: this.onChange('modalNutri', 'observacoes'),
            multiline: true,
          },
        ],
      },
    ];

    return modalsContent.map((modalInfo, index) => (
      <Dialog
        scroll="body"
        key={index}
        open={modalInfo.open}
        onClose={modalInfo.onClose(`${modalInfo.id}`)}
      >
        <DialogContent style={{ padding: 0 }}>
          <div>
            <Card>
              <ModalHeader
                modalImg={modalInfo.modalImg}
                id={modalInfo.id}
                title={modalInfo.title}
                className="mb-3"
              />
              <div className="modal-body">
                <ModalDatePicker
                  show={modalInfo.datePicker ? true : false}
                  placeholder={moment()
                    .utc()
                    .format('DD/MM/YYYY HH:mm')}
                  selectedDate={moment(modalInfo.selectedDate)}
                  handleDateChange={modalInfo.handleDateChange}
                />
                <ModalSelect
                  show={modalInfo.patients ? true : false}
                  error={errors.patient}
                  patient={patient}
                  patients={patients}
                  onChange={this.onChangePatient}
                />
                <SinaisVitais
                  show={modalInfo.sinaisVitais ? true : false}
                  info={modalInfo.sinaisVitais}
                />
                {modalInfo.textfields.map((textfield, index2) => (
                  <TextFieldNormal key={index2} info={textfield} />
                ))}
                <SelectCreatableTreatment
                  show={modalInfo.creatableSelect ? true : false}
                  patient={patient}
                  info={modalInfo.creatableSelect}
                />
                <TextFieldFile
                  show={modalInfo.creatableSelect ? true : false}
                  reference={this.fileInput}
                  onChange={modalInfo.changeFoto}
                  ficheiros={nomesFicheiros}
                  tamanho={fotoTratamento.length}
                />
              </div>
              <ModalFooter
                onClose={modalInfo.onClose}
                onSubmit={modalInfo.onSubmit}
                id={modalInfo.id}
              />
            </Card>
          </div>
        </DialogContent>
      </Dialog>
    ));
  }
}

Modals.defaultProps = {
  treatments: [],
  errors: {},
  toastManager: undefined,
};

Modals.propTypes = {
  patients: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  addOccur: PropTypes.func.isRequired,
  addBandRec: PropTypes.func.isRequired,
  getPatientTreatments: PropTypes.func.isRequired,
  toastManager: PropTypes.func,
  errors: PropTypes.object,
  treatments: PropTypes.array,
};

const mapStateToProps = state => ({
  patients: state.patients,
  treatments: state.patient.treatments,
  modals: state.modals,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    closeModal,
    addOccur,
    addBandRec,
    getPatientTreatments,
  }
)(Modals);
