/* eslint-disable import/prefer-default-export */
import { UPDATE_CONFIG, RESET_CONFIG } from './types';
import axios from 'axios';

// Get config
export const getConfig = () => dispatch => {
  axios
    .get('/api/config')
    .then(res => {
      dispatch({
        type: UPDATE_CONFIG,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: RESET_CONFIG,
      });
    });
};
