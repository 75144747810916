import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

function CardMenu({ menu }) {
  return (
    <Grid container spacing={16} justify="center" className="mt-3">
      <Grid item md={3}>
        <Paper className="p-20">
          <Grid container>
            <Grid item md={2} className="mx-auto">
              <img alt="wiseLogo" className="w-100" src="/images/wiseLogo_hand.png" />
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Typography align="center" variant="h5" className="w-100 mt-4">
              {menu.title}
            </Typography>
          </Grid>
          {/* <Grid container spacing={16} className="mt-3">
          <Grid item md={11} className="mx-auto">
            <img alt="wiseLogo" className="w-100" src={menu.logo} />
          </Grid>
        </Grid> */}
          <Grid container spacing={16} justify="center" className="mt-3">
            <Link to={menu.link}>
              <Button color="primary" variant="contained">
                Aceder
              </Button>
            </Link>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

CardMenu.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default CardMenu;
