import React from 'react';
import Grid from '@material-ui/core/Grid';
import { logoutUser } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import menus from './menus';
import CardMenu from './CardMenu';

function Dealer(props) {
  props.logoutUser();
  const content = menus.map((menu, index) => <CardMenu key={index} menu={menu} />);

  return (
    <Grid
      container
      spacing={16}
      direction="row"
      justify="space-around"
      className="mt-6 content mb-7"
    >
      <Grid container className="mb-4">
        <Grid item md={4} className="mx-auto">
          <img alt="wiseLogo" className="w-100" src="/images/wiseLetters.png" />
        </Grid>
      </Grid>
      {content}
    </Grid>
  );
}

Dealer.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default connect(
  null,
  { logoutUser }
)(Dealer);
