import {
  REGISTER_USER,
  SET_CURRENT_USER,
  GET_USER_INFO,
  UPDATE_PROFILE_PIC,
  GET_INFERIORES,
  SET_CURRENT_USER_PERMISSIONS,
  UPDATE_PERMISSIONS_USER_PERMISSIONS,
} from '../actions/types';
import isEmpty from '../../utils/isEmpty';

const initialState = {
  registration: {
    step: '1',
    email: '',
    name: '',
    codePIN: '',
    phone: '',
  },
  profile: {},
  user: {},
  inferiores: [],
  userPermissions: null,
  isAuthenticated: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        registration: {
          email: action.payload.email,
          name: action.payload.name,
          step: action.payload.step,
          codePIN: action.payload.codePIN,
          phone: action.payload.phone,
        },
      };
    case GET_USER_INFO:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case UPDATE_PROFILE_PIC:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload.avatar,
        },
      };
    case GET_INFERIORES:
      return {
        ...state,
        inferiores: action.payload,
      };
    case SET_CURRENT_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case UPDATE_PERMISSIONS_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: {
          ...state.userPermissions,
          permissions: action.payload,
        },
      };
    default:
      return state;
  }
}
