/* eslint-disable consistent-return */
import {
  GET_PATIENT,
  GET_ERRORS,
  PATIENT_LOADING,
  CLEAR_PATIENT_INFO,
  GET_PATIENT_NURSING_RECS,
  GET_PATIENT_MED_RECS,
  GET_PATIENT_VITALS_RECS,
  GET_PATIENT_BAND_RECS,
  GET_PATIENT_FALL_RECS,
  GET_PATIENT_SOCIAL_RECS,
  GET_PATIENT_NUTRI_RECS,
  GET_PATIENT_MED_NORMAL,
  GET_PATIENT_MED_SOS,
  REGISTOS_LOADING,
  GET_PATIENT_MED_HISTORICO,
  PATIENT_PAUSE_MED_NORMAL,
  PATIENT_PLAY_MED_NORMAL,
  PATIENT_PLAY_MED_SOS,
  GET_PATIENT_INFO_STATS,
  GET_MEDICATION_INFO,
  MEDICATION_INFO_LOADING,
  DELETE_SUSPENDED_MEDICATION,
  DELETE_MEDICATION_SOS,
  PATIENT_CHANGE_MED_NORMAL,
  ADD_PATIENT_MEDICATION,
  UPDATE_PATIENT_STATUS,
  GET_PATIENT_FILES,
  PATIENT_LOADING_FILES,
  DELETE_PATIENT_FILE,
  ADD_PATIENT_FILE,
  ADD_PATIENT_OCCUR,
  STATS_LOADING,
  CLEAR_ERRORS,
  GET_PATIENT_SCALES,
  SCALES_LOADING,
  TREATMENTS_LOADING,
  UPDATE_CLINICAL_NOTE,
  UPDATE_SPECIFIC_CARE,
  ADD_PATIENT_PIC,
  LOAD_PATIENT_PICS,
  LOAD_PATIENT_PDIS,
  SET_CURRENT_PDI,
  PICS_LOADING,
  PDIS_LOADING,
  CLEAR_CURRENT_PDI,
  DELETE_PIC,
  DELETE_PDI,
  GET_PATIENT_RECORDS,
  GET_MOBILE_MODAL,
  SHOW_MOBILE_MODAL,
  CLOSE_MOBILE_MODAL,
  CLEAN_MED,
} from './types';
import axios from 'axios';
import Swal from 'sweetalert2';
import { loadPatients } from './patientsActions';
import download from 'js-file-download';
import { getPatientTreatments } from './occursActions';

import toast from '../../utils/toast';

// Get patient info
export const getPatientInfo = urlName => dispatch => {
  dispatch(setPatientLoading());

  axios
    .get(`/api/patients/patient/${urlName}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get nursing_rec from specific patient
export const getRegistosEnfermagem = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/nursing_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_NURSING_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get med_rec from specific patient
export const getRegistosMedicos = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/med_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_MED_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get vitals_rec from specific patient
export const getRegistosSinaisVitais = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/vitals_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_VITALS_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get band_rec from specific patient
export const getRegistosPensos = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/band_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_BAND_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add specific band_rec to treatment
export const addBandRecToTreatment = (patientId, info) => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .post(`/api/occurs/treatments/band_rec/patient/${patientId}`, info)
    .then(() => {
      dispatch(getRegistosPensos(patientId));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get fall_rec from specific patient
export const getRegistosQuedas = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/fall_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_FALL_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get social_rec from specific patient
export const getRegistosSocial = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/social_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_SOCIAL_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get nutri_rec from specific patient
export const getRegistosNutri = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/occurs/nutri_rec/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_NUTRI_RECS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get medication normal from specific patient
export const getMedicacaoNormal = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/medication/normal/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_MED_NORMAL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get medication sos from specific patient
export const getMedicacaoSOS = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/medication/sos/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_MED_SOS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get medication history from specific patient
export const getMedicacaoHistorico = patientId => dispatch => {
  dispatch(setRegistosLoading());

  axios
    .get(`/api/medication/history/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_MED_HISTORICO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Pause specific medication
export const pauseMedication = (medicationId, anotacao) => dispatch => {
  axios
    .put(`/api/medication/${medicationId}/pause`, { anotacao })
    .then(res => {
      dispatch(setRegistosLoading());
      dispatch({
        type: PATIENT_PAUSE_MED_NORMAL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Play specific medication
export const playMedication = (medicationId, info) => dispatch => {
  axios
    .put(`/api/medication/${medicationId}/play`, { info })
    .then(res => {
      dispatch(setRegistosLoading());
      dispatch({
        type: PATIENT_PLAY_MED_NORMAL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Play specific SOS medication
export const playMedicationSOS = (medicationId, anotacao) => dispatch => {
  axios
    .put(`/api/medication/sos/${medicationId}/play`, { anotacao })
    .then(res => {
      dispatch(setRegistosLoading());
      dispatch({
        type: PATIENT_PLAY_MED_SOS,
        payload: res.data,
      });
      toast({
        type: 'success',
        text: 'Toma de medicamento SOS com sucesso',
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Play specific medication
export const changePosologia = (medicationId, info) => dispatch => {
  axios
    .put(`/api/medication/${medicationId}/update`, { info })
    .then(res => {
      dispatch(setRegistosLoading());
      dispatch({
        type: PATIENT_CHANGE_MED_NORMAL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get information for specfic medication
export const getMedication = medicationId => dispatch => {
  dispatch(setMedicationInfoLoading());
  axios
    .get(`/api/medication/${medicationId}`)
    .then(res => {
      dispatch({
        type: GET_MEDICATION_INFO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete specific medication
export const deleteSuspendedMedication = medicationId => dispatch => {
  dispatch(setMedicationInfoLoading());
  axios
    .delete(`/api/medication/${medicationId}`)
    .then(res => {
      dispatch({
        type: DELETE_SUSPENDED_MEDICATION,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete specific SOS medication
export const deleteMedSOS = medicationId => dispatch => {
  dispatch(setMedicationInfoLoading());
  axios
    .delete(`/api/medication/sos/${medicationId}`)
    .then(res => {
      dispatch({
        type: DELETE_MEDICATION_SOS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete specific PIC
export const deletePic = (patient_id, pic_id) => dispatch => {
  axios
    .delete(`/api/patients/patient/${patient_id}/pic/${pic_id}`)
    .then(res => {
      dispatch({
        type: DELETE_PIC,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete specific PDI
export const deletePdi = (patient_id, pdi_id) => dispatch => {
  axios
    .delete(`/api/patients/patient/${patient_id}/pdi/${pdi_id}`)
    .then(res => {
      dispatch({
        type: DELETE_PDI,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new medication to patient
export const addMedication = (patientId, info) => dispatch => {
  dispatch(setMedicationInfoLoading());
  axios
    .post(`/api/medication/patient/${patientId}`, { ...info })
    .then(res => {
      dispatch({
        type: ADD_PATIENT_MEDICATION,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get Numero de registos
export const getRegStats = urlName => dispatch => {
  dispatch(setStatsLoading());
  axios
    .get(`/api/patients/${urlName}/stats`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_INFO_STATS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update patient status
export const updateStatus = (patientId, label, status) => dispatch => {
  Swal({
    title: 'Alterar estado',
    text: `Deseja alterar o estado do utente para ${label}?`,
    type: 'info',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
  }).then(result => {
    if (result.value !== undefined) {
      dispatch(setPatientLoading());
      axios
        .put(`/api/patients/${patientId}/change-status`, { status })
        .then(res => {
          dispatch(loadPatients());
          dispatch({
            type: UPDATE_PATIENT_STATUS,
            payload: res.data,
          });

          return Swal({
            title: 'Alterado',
            text: `O estado do utente foi alterado com sucesso para ${label}.`,
            type: 'success',
            confirmButtonColor: '#007765',
            cancelButtonColor: '#73797f',
          });
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } else {
      return Swal({
        title: 'Cancelado',
        text: 'O estado do utente não foi alterado',
        type: 'info',
        confirmButtonColor: '#007765',
        cancelButtonColor: '#73797f',
      });
    }
  });
};

// Get patient files
export const loadPatientFiles = patientId => dispatch => {
  dispatch(setPatientFilesLoading());
  axios
    .get(`/api/files/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_FILES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Download patient file
export const downloadPatientFile = (fileId, patientId, filename) => dispatch => {
  axios
    .get(`/api/files/${patientId}/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      download(res.data, filename);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete patient file
export const deletePatientFile = (fileId, patientId, filename) => dispatch => {
  Swal({
    title: 'Eliminar ficheiro',
    text: `Deseja eliminar o ficheiro ${filename}?`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
  }).then(result => {
    if (result.value) {
      dispatch(setStatsLoading());
      axios
        .delete(`/api/files/patient/${patientId}/${fileId}`)
        .then(res => {
          dispatch({
            type: DELETE_PATIENT_FILE,
            payload: res.data,
          });
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal({
        title: 'Cancelado',
        text: 'O ficheiro não foi eliminado',
        type: 'info',
        confirmButtonColor: '#007765',
      });
    }

    return true;
  });
};

// Get Numero de registos
export const uploadPatientFiles = (patientId, files) => dispatch => {
  dispatch(setPatientFilesLoading());
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .post(`/api/files/patient/${patientId}`, files, config)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_FILE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new patient occur
export const addPatientOccur = (type, patientId, data) => dispatch => {
  dispatch(setRegistosLoading());
  axios
    .post(`/api/occurs/${type}/patient/${patientId}`, data)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_OCCUR,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// GET patient Scales
export const getPatientScales = patientID => dispatch => {
  axios
    .get(`/api/patients/patient/${patientID}/braden-morse`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_SCALES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// EDIT patient Nota Clinica
export const editNotaClinica = (patientId, data) => dispatch => {
  axios
    .put(`/api/patients/patient/${patientId}/clinical-note`, data)
    .then(res => {
      dispatch({
        type: UPDATE_CLINICAL_NOTE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Edit patient specific care
export const editSpecificCare = (patientId, data) => dispatch => {
  axios
    .put(`/api/patients/patient/${patientId}/specific-care`, { observacoes: data })
    .then(res => {
      dispatch({
        type: UPDATE_SPECIFIC_CARE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// Add new patient band_rec
export const addPatientBandRec = (patientId, data) => dispatch => {
  dispatch(setRegistosLoading());

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .post(`/api/occurs/band_rec/patient/${patientId}`, data, config)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_OCCUR,
        payload: res.data,
      });
      dispatch(getPatientTreatments(patientId));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new scale to patient
export const addScale = (patientId, type, info) => dispatch => {
  dispatch(setScalesLoading());

  axios
    .post(`/api/patients/patient/${patientId}/${type}`, info)
    .then(() => {
      dispatch(getPatientScales(patientId));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete occur
export const deleteOccur = (occurId, patientId, type) => dispatch => {
  axios
    .delete(`/api/occurs/${patientId}/${occurId}`)
    .then(() => {
      switch (type) {
        case 'nursing_rec':
          return dispatch(getRegistosEnfermagem(patientId));
        case 'med_rec':
          return dispatch(getRegistosMedicos(patientId));
        case 'band_rec':
          return dispatch(getRegistosPensos(patientId));
        case 'fall_rec':
          return dispatch(getRegistosQuedas(patientId));
        case 'social_rec':
          return dispatch(getRegistosSocial(patientId));
        case 'nutri_rec':
          return dispatch(getRegistosNutri(patientId));
        case 'vitals_rec':
          return dispatch(getRegistosSinaisVitais(patientId));
        default:
          return true;
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const addNewPic = (patientId, data) => dispatch => {
  axios
    .post(`/api/patients/patient/${patientId}/pic`, data)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_PIC,
        payload: res.data,
      });
    })
    .catch(err => {
      // Caso haja erro, é no topo da página
      window.scrollTo(0, 0);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPatientPics = patientId => dispatch => {
  dispatch(setPicsLoading());
  axios
    .get(`/api/patients/patient/${patientId}/pic`)
    .then(res => {
      dispatch({
        type: LOAD_PATIENT_PICS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPatientPdis = patientId => dispatch => {
  dispatch(setPdisLoading());

  axios
    .get(`/api/patients/patient/${patientId}/pdi`)
    .then(res => {
      dispatch({
        type: LOAD_PATIENT_PDIS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const addPatientPdi = (patientId, pdiInfo) => dispatch => {
  axios
    .post(`/api/patients/patient/${patientId}/pdi`, pdiInfo)
    .then(res => {
      dispatch(getPatientPdis(patientId));
      dispatch({
        type: SET_CURRENT_PDI,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const startNewPdi = (patientId, pdiId) => dispatch => {
  axios
    .post(`/api/patients/patient/${patientId}/pdi/${pdiId}`)
    .then(res => {
      dispatch(getPatientPdis(patientId));
      dispatch({
        type: SET_CURRENT_PDI,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updatePatientPdi = (patientId, pdiId, pdiInfo) => dispatch => {
  axios
    .put(`/api/patients/patient/${patientId}/pdi/${pdiId}`, pdiInfo)
    .then(res => {
      dispatch({
        type: SET_CURRENT_PDI,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPdiInfo = (patientId, pdiId) => dispatch => {
  axios
    .get(`/api/patients/patient/${patientId}/pdi/${pdiId}`)
    .then(res => {
      dispatch({
        type: SET_CURRENT_PDI,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patient records
export const getPatientRecords = patient_id => dispatch => {
  axios
    .get(`/api/patients/patient/patient-record/all/${patient_id}`)
    .then(res => {
      dispatch({
        type: GET_PATIENT_RECORDS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const cleanMed = () => {
  return {
    type: CLEAN_MED,
  };
};

// Set what modal will open
export const openMobileModal = type => {
  return {
    type: GET_MOBILE_MODAL,
    payload: type,
  };
};

// Open modal
export const showMobileModal = () => {
  return {
    type: SHOW_MOBILE_MODAL,
  };
};
export const closeMobileModal = () => {
  return {
    type: CLOSE_MOBILE_MODAL,
  };
};

export const clearCurrentPdi = () => {
  return {
    type: CLEAR_CURRENT_PDI,
  };
};

// Set loading flag
export const setPatientLoading = () => {
  return {
    type: PATIENT_LOADING,
  };
};

// Set registos loading flag
export const setRegistosLoading = () => {
  return {
    type: REGISTOS_LOADING,
  };
};

// Set stats loading flag
export const setStatsLoading = () => {
  return {
    type: STATS_LOADING,
  };
};

// Set medication info loading flag
export const setMedicationInfoLoading = () => {
  return {
    type: MEDICATION_INFO_LOADING,
  };
};

// Set files loading flag
export const setPatientFilesLoading = () => {
  return {
    type: PATIENT_LOADING_FILES,
  };
};

// Set treatments loading flag
export const setTreatmentsLoading = () => {
  return {
    type: TREATMENTS_LOADING,
  };
};

// Set scales loading flag
export const setScalesLoading = () => {
  return {
    type: SCALES_LOADING,
  };
};

export const setPicsLoading = () => {
  return {
    type: PICS_LOADING,
  };
};

export const setPdisLoading = () => {
  return {
    type: PDIS_LOADING,
  };
};

// Clear user info
export const clearPatientInfo = () => {
  return {
    type: CLEAR_PATIENT_INFO,
    payload: {},
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
