import {
  GET_EVENTS,
  GET_EVENTS_NEXT_WEEK,
  // GET_PATIENT_EVENTS,
  ADD_EVENT,
  DELETE_EVENT,
  // ADD_PATIENT_EVENT,
  EDIT_EVENT,
  EVENTS_LOADING,
  GET_ERRORS,
  CLOSE_INFO,
  // OPEN_INFO
} from './types';

import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import toast from '../../utils/toast';

// Get all events from all patients
export const getEvents = () => dispatch => {
  dispatch(setEventsLoading());

  axios
    .get('/api/events')
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get all events from specific patient
export const getEventsPatient = patientId => dispatch => {
  dispatch(setEventsLoading());

  axios
    .get(`/api/events/patient/${patientId}`)
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new event
export const addEvent = eventData => dispatch => {
  axios
    .post('/api/events', eventData)
    .then(res => {
      dispatch({
        type: ADD_EVENT,
        payload: res.data,
      });
      toast({
        type: 'success',
        text: 'Evento adicionado',
      });
      dispatch(getWeekEvents());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Change event end date
export const eventResize = eventData => dispatch => {
  Swal({
    title: 'Editar evento',
    text: `Deseja alterar a hora do final deste evento para as ${moment(eventData.event.end).format(
      'HH:mm'
    )}?`,
    type: 'info',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
  }).then(result => {
    if (result.value) {
      const eventInfo = {
        id: eventData.event.id,
        end: eventData.event.end,
      };
      axios
        .put('/api/events/endDate', eventInfo)
        .then(res => {
          dispatch(updateEventData(res.data));
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      eventData.revert();
      Swal({
        title: 'Cancelado',
        text: 'A data do evento não foi alterada',
        type: 'info',
        confirmButtonColor: '#007765',
      });
    }
  });
};

// Change event start date
export const eventChangeStart = eventData => dispatch => {
  Swal({
    title: 'Editar evento',
    text: `Deseja mover este evento para as ${moment(eventData.event.start).format(
      'HH:mm'
    )} de ${moment(eventData.event.start).format('LL')}?`,
    type: 'info',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
  }).then(result => {
    if (result.value) {
      const eventInfo = {
        id: eventData.event.id,
        start: eventData.event.start,
        end: eventData.event.end,
      };
      axios
        .put('/api/events/startDate', eventInfo)
        .then(res => {
          dispatch(updateEventData(res.data));
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      eventData.revert();
      Swal({
        title: 'Cancelado',
        text: 'O evento não foi movido',
        type: 'info',
        confirmButtonColor: '#007765',
      });
    }
  });
};

// Delete event
export const deleteEvent = eventData => dispatch => {
  const eventType = getEventType(eventData.type);

  dispatch(closeModalInfo());

  Swal({
    title: 'Eliminar evento',
    text: `Tem a certeza que pretende eliminar ${eventType}${
      eventData.patient_id !== null ? ` de ${eventData.patient_id.shortName}?` : '?'
    }`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Eliminar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
  }).then(result => {
    if (result.value) {
      const eventId = eventData._id;
      axios
        .delete(`/api/events/${eventId}`)
        .then(res => {
          dispatch({
            type: DELETE_EVENT,
            payload: res.data,
          });
          toast({
            type: 'success',
            text: 'Evento removido',
          });
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal({
        title: 'Cancelado',
        text: 'O evento não foi eliminado',
        type: 'info',
        confirmButtonColor: '#007765',
      });
    }
  });
};

// Update event
export const updateEvent = eventData => dispatch => {
  axios
    .put(`/api/events/${eventData._id}`, eventData)
    .then(res => {
      dispatch(updateEventData(res.data));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get events for the next week
export const getWeekEvents = () => dispatch => {
  dispatch(setEventsLoading());

  axios
    .get('/api/events/nextWeek')
    .then(res => {
      dispatch({
        type: GET_EVENTS_NEXT_WEEK,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setEventDone = id => dispatch => {
  Swal({
    text: 'Adicione uma anotação ao evento',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'Guardar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#007765',
    cancelButtonColor: '#73797f',
    allowOutsideClick: true,
  }).then(result => {
    if (result.value !== undefined) {
      const anotacao = result.value;
      const infoData = {
        id,
        anotacao,
      };

      dispatch(setEventsLoading());

      axios
        .put('/api/events/done', infoData)
        .then(res => {
          dispatch(getWeekEvents());
          dispatch(updateEventData(res.data));
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        });
    }
  });
};

// Set loading flag
export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING,
  };
};

// Update event data
export const updateEventData = data => {
  toast({
    type: 'success',
    text: 'Evento atualizado',
  });
  return {
    type: EDIT_EVENT,
    payload: data,
  };
};

// Close modal info
export const closeModalInfo = () => {
  return {
    type: CLOSE_INFO,
  };
};

const getEventType = type => {
  switch (type) {
    case 'Consulta':
      return 'a Consulta';
    case 'Analises':
      return 'as Análises';
    case 'Algaliacao':
      return 'a Algaliação';
    case 'Penso':
      return 'o Penso';
    case 'Entubacao':
      return 'a Entubação';
    case 'Exames':
      return 'os Exames';
    case 'Lembrete':
      return 'o Lembrete';
    default:
      return type;
  }
};
