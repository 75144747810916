import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import patientsReducer from './patientsReducer';
import patientReducer from './patientReducer';
import occursReducer from './occursReducer';
import tasklistReducer from './tasklistReducer';
import agendaReducer from './agendaReducer';
import modalsReducer from './modalsReducer';
import eventsReducer from './eventsReducer';
import filesReducer from './filesReducer';
import medicationReducer from './medicationReducer';
import historyReducer from './historyReducer';
import configReducer from './configReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  patients: patientsReducer,
  patient: patientReducer,
  occurs: occursReducer,
  tasklist: tasklistReducer,
  agenda: agendaReducer,
  modals: modalsReducer,
  events: eventsReducer,
  files: filesReducer,
  medication: medicationReducer,
  history: historyReducer,
  config: configReducer,
});
