const menus = [
  {
    title: 'Plataforma Cliníca',
    logo: '/images/wiseLogo.png',
    link: '/clinica',
  },
  {
    title: 'Plataforma de Gestão',
    logo: '/images/platGestao.png',
    link: '/gestao',
  },
];

export default menus;
