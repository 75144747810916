import React, { Component } from 'react';
import { recoverPassword } from '../../store/actions/authActions';
import { Button, TextField, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class RecuperarForm extends Component {
  state = {
    email: '',
    errors: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      return this.setState({
        errors: this.props.errors,
      });
    }

    return true;
  }

  onSubmit = e => {
    e.preventDefault();

    const { email } = this.state;

    if (email === '') {
      return this.setState({
        errors: {
          email: 'Insira o seu email',
        },
      });
    }

    return this.props.recoverPassword(email);
  };

  onChange = input => e => this.setState({ [input]: e.target.value });

  render() {
    const { errors, email } = this.state;

    return (
      <Grid container justify="center" className="mt-6 content">
        <Grid container spacing={16} justify="center">
          <Grid item md={4}>
            <img alt="csclogo" className="w-100" src="/images/wiseLogo.png" />
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Paper className="p-20" style={{ marginTop: 50 }}>
            <Grid
              container
              className="mb-5"
              spacing={16}
              style={{ marginTop: 50 }}
              justify="center"
            >
              <Grid item md={6} className="mx-auto">
                <img alt="csclogo" className="w-100" src="/images/logo.png" />
              </Grid>
            </Grid>
            <Grid container spacing={16} justify="center">
              <Grid item md={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors.email ? true : false}
                  helperText={errors ? errors.email : null}
                  label="Email"
                  placeholder="Insira o seu email"
                  type="email"
                  className="mb-2"
                  value={email}
                  onChange={this.onChange('email')}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-4 mb-3" spacing={16} justify="center">
              <Button onClick={this.onSubmit} className="mt-2" variant="contained" color="primary">
                Recuperar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

RecuperarForm.defaultProps = {
  errors: {},
};

RecuperarForm.propTypes = {
  recoverPassword: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = state => ({
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { recoverPassword }
)(RecuperarForm);
