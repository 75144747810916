import {
  ADD_MEDICATION,
  DELETE_MEDICATION,
  GET_MEDICATION,
  MEDICATION_LOADING,
  PATIENT_MEDICATION,
  CLEAN_TABLES,
} from '../actions/types';

const initialState = {
  medicines: [],
  medication: {},
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MEDICATION:
      return {
        ...state,
        medicines: action.payload,
        loading: false,
      };
    case ADD_MEDICATION:
      return {
        ...state,
        medicines: [action.payload, ...state.medicines],
        loading: false,
      };
    case DELETE_MEDICATION:
      return {
        ...state,
        medicines: [
          ...state.medicines.filter(medicine => {
            return medicine._id !== action.payload._id;
          }),
        ],
        loading: false,
      };
    case PATIENT_MEDICATION:
      return {
        ...state,
        medication: {
          ...state.medication,
          normal: action.payload.normal,
          sos: action.payload.sos,
        },
        loading: false,
      };
    case CLEAN_TABLES:
      return {
        ...state,
        medication: [],
        loading: false,
      };

    case MEDICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
