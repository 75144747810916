import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PersonRounded from '@material-ui/icons/PersonRounded';
import ExitToAppRounded from '@material-ui/icons/ExitToAppRounded';
import LockRounded from '@material-ui/icons/LockRounded';
import { connect } from 'react-redux';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { logoutUser } from '../../store/actions/authActions';
import Select from 'react-select';
import { loadPatients } from '../../store/actions/patientsActions';
import { getEvents } from '../../store/actions/eventsActions';
import { getConfig } from '../../store/actions/configActions';
import isEmpty from '../../utils/isEmpty';
import { DropdownIndicator, SingleValue, Option } from './common';
import customStyles from './common/customStylesNavbar';
import Menus from './Menus';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: theme.spacing.unit,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  sectionDesktop: {
    cursor: 'pointer',
    display: 'none',
    padding: '10px',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#4da093',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

export class Navbar extends Component {
  state = {
    shouldShow: null,
    lastScroll: null,
    patient: null,
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.props.loadPatients();
    this.props.getConfig();
    return this.props.getEvents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (this.props.auth.isAuthenticated) {
        this.props.loadPatients();
        this.props.getConfig();
        return this.props.getEvents();
      }
    }

    return true;
  }

  onChangePatient = e => {
    this.setState({ patient: e });
    if (!isEmpty(e)) {
      this.setState({ patient: null });
      this.props.history.push(`/clinica/utente/${e.url_name}`);
    }
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleUserProfile = e => {
    e.preventDefault();
    this.props.history.push(`/clinica/utilizador/${this.props.auth.user.username}`);
  };

  handlePermissions = e => {
    e.preventDefault();
    this.props.history.push('/clinica/alterar-permissoes');
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleSearchClick = e => {
    e.preventDefault();
    const { patient } = this.state;
    this.props.history.push(`/clinica/utente/${patient.url_name}`);
  };

  handleLogout = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  getScrollClassName = () => {
    if (this.state.shouldShow === null) {
      return '';
    }

    return this.state.shouldShow ? this.props.classes.show : this.props.classes.hide;
  };

  handleScroll = () => {
    const lastScroll = window.scrollY;

    if (lastScroll === this.state.lastScroll) {
      return;
    }

    const shouldShow = this.lastScroll !== null ? lastScroll < this.lastScroll : null;

    if (shouldShow !== this.state.shouldShow) {
      this.setState(prevState => ({
        ...prevState,
        shouldShow,
      }));
    }

    this.lastScroll = lastScroll;
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { patient, anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, isMobile } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { patients } = this.props.patients;

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen ? true : false}
        onClose={this.handleMenuClose}
        className="mt-5"
      >
        <MenuItem onClick={this.handleUserProfile}>
          <PersonRounded /> Perfil pessoal
        </MenuItem>
        <Divider />
        {user.permissions !== undefined && user.permissions.boss && (
          <MenuItem onClick={this.handlePermissions}>
            <LockRounded /> Alterar permissões
          </MenuItem>
        )}
        {user.permissions !== undefined && user.permissions.boss && <Divider />}
        <MenuItem
          onClick={e => {
            this.handleMenuClose();
            this.handleLogout(e);
          }}
        >
          <ExitToAppRounded /> Terminar sessão
        </MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen ? true : false}
        onClose={this.handleMobileMenuClose}
        className="mt-5"
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <PersonRounded /> Perfil pessoal
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={e => {
            this.handleProfileMenuOpen(e);
            this.handleLogout(e);
          }}
        >
          <ExitToAppRounded /> Terminar sessão
        </MenuItem>
      </Menu>
    );

    if (isAuthenticated && user.clinica) {
      return (
        <div>
          <Hidden smDown>
            <AppBar
              className={isMobile ? `${classes.root} ${this.getScrollClassName()}` : null}
              style={{ minHeight: '3.5rem' }}
            >
              <Toolbar disableGutters>
                <Grid container>
                  <Grid item md={3} className="d-flex justify-content-start">
                    <div className={classes.search}>
                      <Select
                        fullWidth
                        isClearable
                        components={{
                          DropdownIndicator,
                          Option,
                          SingleValue,
                        }}
                        styles={customStyles}
                        onChange={this.onChangePatient}
                        placeholder="Procurar utente..."
                        className={classes.inputInput}
                        value={patient}
                        options={patients}
                        getOptionValue={option => option._id}
                        getOptionLabel={option =>
                          `${option.name} ${option.shortName} ${option.number}`
                        }
                        noOptionsMessage={() => {
                          if (isEmpty(patients)) {
                            return 'A carregar utentes...';
                          }

                          return 'Nenhum utente satisfaz a pesquisa';
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item md={7} className="d-flex justify-content-center align-items-center">
                    <Menus />
                  </Grid>
                  <Grid item md={2} className="d-flex justify-content-end align-items-center">
                    <div className={classes.sectionDesktop} onClick={this.handleProfileMenuOpen}>
                      <Avatar
                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        alt={`${user.name}`}
                        src={`/images/avatar/${user.avatar}`}
                      />
                      <div className="align-self-center ml-2">
                        <Typography style={{ color: '#fff' }} variant="subtitle2">
                          {user.name}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  {/* {isMobile ? (
                  <MobileNavBar user={user} isMenuOpen={isMenuOpen} patients={patients} />
                ) : null} */}
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>

          {renderMenu}
          {renderMobileMenu}
        </div>
      );
    }

    return null;
  }
}

Navbar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  patients: PropTypes.object.isRequired,
  loadPatients: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getConfig: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  patients: state.patients,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      logoutUser,
      loadPatients,
      getEvents,
      getConfig,
    }
  )(withStyles(styles)(Navbar))
);
