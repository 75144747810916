export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SUCCESS_TOAST = 'SUCCESS_TOAST';
export const ERROR_TOAST = 'ERROR_TOAST';
export const WARNING_TOAST = 'WARNING_TOAST';
export const GET_PATIENTS = 'GET_PATIENTS';
export const ADD_PATIENT = 'ADD_PATIENT';
export const GET_PATIENTS_LAR = 'GET_PATIENTS_LAR';
export const GET_PATIENTS_CENTRO_DIA = 'GET_PATIENTS_CENTRO_DIA';
export const GET_PATIENTS_ARQUIVO = 'GET_PATIENTS_ARQUIVO';
export const GET_PATIENT = 'GET_PATIENT';
export const PATIENTS_LOADING = 'PATIENTS_LOADING';
export const GET_OCCURS = 'GET_OCCURS';
export const ADD_OCCUR = 'ADD_OCCUR';
export const OCCURS_LOADING = 'OCCURS_LOADING';
export const GET_UNCLEARED_TASKLIST = 'GET_UNCLEARED_TASKLIST';
export const GET_CLEARED_TASKLIST = 'GET_CLEARED_TASKLIST';
export const TASKLIST_LOADING = 'TASKLIST_LOADING';
export const ADD_TASK = 'ADD_TASK';
export const TOGGLE_TASK = 'TOGGLE_TASK';
export const GET_UNDONE_AGENDA = 'GET_UNDONE_AGENDA';
export const GET_DONE_AGENDA = 'GET_DONE_AGENDA';
export const AGENDA_LOADING = 'AGENDA_LOADING';
export const OPEN_ENFERMAGEM = 'OPEN_ENFERMAGEM';
export const CLOSE_ENFERMAGEM = 'CLOSE_ENFERMAGEM';
export const OPEN_MEDICO = 'OPEN_MEDICO';
export const CLOSE_MEDICO = 'CLOSE_MEDICO';
export const OPEN_SINAIS_VITAIS = 'OPEN_SINAIS_VITAIS';
export const CLOSE_SINAIS_VITAIS = 'CLOSE_SINAIS_VITAIS';
export const OPEN_PENSOS = 'OPEN_PENSOS';
export const CLOSE_PENSOS = 'CLOSE_PENSOS';
export const OPEN_QUEDAS = 'OPEN_QUEDAS';
export const CLOSE_QUEDAS = 'CLOSE_QUEDAS';
export const OPEN_EVENTO = 'OPEN_EVENTO';
export const CLOSE_EVENTO = 'CLOSE_EVENTO';
export const OPEN_CONSULTA = 'OPEN_CONSULTA';
export const CLOSE_CONSULTA = 'CLOSE_CONSULTA';
export const OPEN_ANALISES = 'OPEN_ANALISES';
export const CLOSE_ANALISES = 'CLOSE_ANALISES';
export const OPEN_ALGALIACAO = 'OPEN_ALGALIACAO';
export const CLOSE_ALGALIACAO = 'CLOSE_ALGALIACAO';
export const OPEN_PENSO = 'OPEN_PENSO';
export const CLOSE_PENSO = 'CLOSE_PENSO';
export const OPEN_ENTUBACAO = 'OPEN_ENTUBACAO';
export const CLOSE_ENTUBACAO = 'CLOSE_ENTUBACAO';
export const OPEN_EXAMES = 'OPEN_EXAMES';
export const CLOSE_EXAMES = 'CLOSE_EXAMES';
export const OPEN_INFO = 'OPEN_INFO';
export const CLOSE_INFO = 'CLOSE_INFO';
export const OPEN_LEMBRETE = 'OPEN_LEMBRETE';
export const CLOSE_LEMBRETE = 'CLOSE_LEMBRETE';
export const OPEN_SOCIAL = 'OPEN_SOCIAL';
export const CLOSE_SOCIAL = 'CLOSE_SOCIAL';
export const OPEN_NUTRI = 'OPEN_NUTRI';
export const CLOSE_NUTRI = 'CLOSE_NUTRI';
export const ADD_PATIENT_TREATMENTS = 'ADD_PATIENT_TREATMENTS';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_NEXT_WEEK = 'GET_EVENTS_NEXT_WEEK';
export const GET_PATIENT_EVENTS = 'GET_PATIENT_EVENTS';
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const ADD_PATIENT_EVENT = 'ADD_PATIENT_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const EVENTS_LOADING = 'EVENTS_LOADING';
export const GET_FILES = 'GET_FILES';
export const FILES_LOADING = 'FILES_LOADING';
export const ADD_FILE = 'ADD_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const ADD_MEDICATION = 'ADD_MEDICATION';
export const GET_MEDICATION = 'GET_MEDICATION';
export const DELETE_MEDICATION = 'DELETE_MEDICATION';
export const MEDICATION_LOADING = 'MEDICATION_LOADING';
export const PATIENT_MEDICATION = 'PATIENT_MEDICATION';
export const CLEAN_TABLES = 'CLEAN_TABLES';
export const HISTORY_LOADING = 'HISTORY_LOADING';
export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_PATIENTS = 'GET_HISTORY_PATIENTS';
export const GET_HISTORY_STAFF = 'GET_HISTORY_STAFF';
export const HISTORY_NOT_LOADING = 'HISTORY_NOT_LOADING';
export const GET_HISTORY_PAST = 'GET_HISTORY_PAST';
export const GET_USER_INFO = 'GET_USER_INFO';
export const PATIENT_LOADING = 'PATIENT_LOADING';
export const CLEAR_PATIENT_INFO = 'CLEAR_PATIENT_INFO';
export const GET_PATIENT_NURSING_RECS = 'GET_PATIENT_NURSING_RECS';
export const GET_PATIENT_MED_RECS = 'GET_PATIENT_MED_RECS';
export const GET_PATIENT_BAND_RECS = 'GET_PATIENT_BAND_RECS';
export const GET_PATIENT_FALL_RECS = 'GET_PATIENT_FALL_RECS';
export const GET_PATIENT_VITALS_RECS = 'GET_PATIENT_VITALS_RECS';
export const GET_PATIENT_SOCIAL_RECS = 'GET_PATIENT_SOCIAL_RECS';
export const GET_PATIENT_NUTRI_RECS = 'GET_PATIENT_NUTRI_RECS';
export const GET_PATIENT_MED_NORMAL = 'GET_PATIENT_MED_NORMAL';
export const GET_PATIENT_MED_SOS = 'GET_PATIENT_MED_SOS';
export const GET_PATIENT_MED_HISTORICO = 'GET_PATIENT_MED_HISTORICO';
export const PATIENT_PAUSE_MED_NORMAL = 'PATIENT_PAUSE_MED_NORMAL';
export const PATIENT_PLAY_MED_NORMAL = 'PATIENT_PLAY_MED_NORMAL';
export const PATIENT_PLAY_MED_SOS = 'PATIENT_PLAY_MED_SOS';
export const PATIENT_CHANGE_MED_NORMAL = 'PATIENT_CHANGE_MED_NORMAL';
export const REGISTOS_LOADING = 'REGISTOS_LOADING';
export const STATS_LOADING = 'STATS_LOADING';
export const GET_PATIENT_INFO_STATS = 'GET_PATIENT_INFO_STATS';
export const GET_MEDICATION_INFO = 'GET_MEDICATION_INFO';
export const MEDICATION_INFO_LOADING = 'MEDICATION_INFO_LOADING';
export const DELETE_SUSPENDED_MEDICATION = 'DELETE_SUSPENDED_MEDICATION';
export const DELETE_MEDICATION_SOS = 'DELETE_MEDICATION_SOS';
export const ADD_PATIENT_MEDICATION = 'ADD_PATIENT_MEDICATION';
export const UPDATE_PATIENT_STATUS = 'UPDATE_PATIENT_STATUS';
export const GET_PATIENT_FILES = 'GET_PATIENT_FILES';
export const PATIENT_LOADING_FILES = 'PATIENT_LOADING_FILES';
export const ADD_PATIENT_FILE = 'ADD_PATIENT_FILE';
export const DELETE_PATIENT_FILE = 'DELETE_PATIENT_FILE';
export const ADD_PATIENT_OCCUR = 'ADD_PATIENT_OCCUR';
export const GET_PATIENT_SCALES = 'GET_PATIENT_SCALES';
export const SCALES_LOADING = 'SCALES_LOADING';
export const TREATMENTS_LOADING = 'TREATMENTS_LOADING';
export const END_PATIENT_TREATMENT = 'END_PATIENT_TREATMENT';
export const ADD_PATIENT_HISTORY_TREATMENTS = 'ADD_PATIENT_HISTORY_TREATMENTS';
export const UPDATE_CLINICAL_NOTE = 'UPDATE_CLINICAL_NOTE';
export const UPDATE_SPECIFIC_CARE = 'UPDATE_SPECIFIC_CARE';
export const CLEAN_FILTERED = 'CLEAN_FILTERED';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const ADD_PATIENT_PIC = 'ADD_PATIENT_PIC';
export const LOAD_PATIENT_PICS = 'LOAD_PATIENT_PICS';
export const PICS_LOADING = 'PICS_LOADING';
export const PDIS_LOADING = 'PDIS_LOADING';
export const LOAD_PATIENT_PDIS = 'LOAD_PATIENT_PDIS';
export const SET_CURRENT_PDI = 'SET_CURRENT_PDI';
export const CLEAR_CURRENT_PDI = 'CLEAR_CURRENT_PDI';
export const DELETE_PIC = 'DELETE_PIC';
export const DELETE_PDI = 'DELETE_PDI';
export const GET_CONFIG = 'GET_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const RESET_CONFIG = 'RESET_CONFIG';
export const GET_PATIENT_RECORDS = 'GET_PATIENT_RECORDS';
export const GET_INFERIORES = 'GET_INFERIORES';
export const SET_CURRENT_USER_PERMISSIONS = 'SET_CURRENT_USER_PERMISSIONS';
export const UPDATE_PERMISSIONS_USER_PERMISSIONS = 'UPDATE_PERMISSIONS_USER_PERMISSIONS';
export const GET_MOBILE_MODAL = 'GET_MOBILE_MODAL';
export const SHOW_MOBILE_MODAL = 'SHOW_MOBILE_MODAL';
export const CLOSE_MOBILE_MODAL = 'CLOSE_MOBILE_MODAL';
export const CLEAN_MED = 'CLEAN_MED';
