import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const TextFieldNormal = ({ info }) => {
  return (
    <TextField
      fullWidth
      className="mb-3"
      label={info.label}
      value={info.value}
      multiline={info.multiline}
      rows={info.multiline ? '3' : null}
      variant="outlined"
      name={info.name}
      onChange={info.onChange}
    />
  );
};

TextFieldNormal.propTypes = {
  info: PropTypes.object.isRequired
};

export default TextFieldNormal;
