import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

const ModalFooter = ({ onClose, onSubmit, id }) => {
  return (
    <div className="modal-footer">
      <Tooltip title="Adicionar registo" aria-label="Add">
        <IconButton onClick={onSubmit(`${id}`)} color="primary">
          <Save />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancelar" aria-label="cancel">
        <IconButton onClick={onClose(`${id}`)}>
          <Cancel />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ModalFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ModalFooter;
