import React, { Component } from 'react';
import { changePassword } from '../../store/actions/authActions';
import { Button, TextField, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class RecuperarForm extends Component {
  state = {
    password: '',
    checkPassword: '',
    errors: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      return this.setState({
        errors: this.props.errors,
      });
    }

    return true;
  }

  onSubmit = e => {
    e.preventDefault();

    const { password, checkPassword } = this.state;
    const { code } = this.props.match.params;

    if (password !== checkPassword) {
      return this.setState({
        errors: {
          password: 'As palavras-passe não coincidem',
          checkPassword: 'As palavras-passe não coincidem',
        },
      });
    }

    const info = {
      password,
      code,
      checkPassword,
    };

    return this.props.changePassword(info, this.props.history);
  };

  onChange = input => e => this.setState({ [input]: e.target.value });

  render() {
    const { errors, password, checkPassword } = this.state;

    return (
      <Grid container justify="center" className="mt-6 content">
        <Grid container spacing={16} justify="center">
          <Grid item md={4}>
            <img alt="csclogo" className="w-100" src="/images/wiseLogo.png" />
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Paper className="p-20" style={{ marginTop: 50 }}>
            <Grid
              container
              className="mb-5"
              spacing={16}
              style={{ marginTop: 50 }}
              justify="center"
            >
              <Grid item md={6} className="mx-auto">
                <img alt="csclogo" className="w-100" src="/images/logo.png" />
              </Grid>
            </Grid>
            <Grid container spacing={16} justify="center">
              <Grid item md={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors.password ? true : false}
                  helperText={errors ? errors.password : null}
                  label="Palavra-passe"
                  placeholder="Insira uma nova palavra-passe"
                  type="password"
                  className="mb-2"
                  value={password}
                  onChange={this.onChange('password')}
                />
              </Grid>
              <Grid item md={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors.checkPassword ? true : false}
                  helperText={errors ? errors.checkPassword : null}
                  label="Confirmar palavra-passe"
                  placeholder="Confirme a sua nova palavra-passe"
                  type="password"
                  className="mb-2"
                  value={checkPassword}
                  onChange={this.onChange('checkPassword')}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-2" spacing={16} justify="space-around">
              <Button onClick={this.onSubmit} className="mt-2" variant="contained" color="primary">
                Alterar palavra-passe
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

RecuperarForm.defaultProps = {
  errors: {},
};

RecuperarForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = state => ({
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { changePassword }
)(withRouter(RecuperarForm));
