import { UPDATE_CONFIG, RESET_CONFIG } from '../actions/types';
import config from '../../utils/config';

const initialState = {
  name: config.name,
  shortName: config.shortName,
  email: config.email ? config.email : undefined,
  phone: config.phone ? config.phone : undefined,
  fax: config.fax ? config.fax : undefined,
  address: config.address ? config.address : undefined,
  postalCode: config.postalCode ? config.postalCode : undefined,
  website: config.website ? config.website : undefined,
  medic: config.medic ? config.medic : undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_CONFIG:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
