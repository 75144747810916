import {
  GET_UNCLEARED_TASKLIST,
  ADD_TASK,
  TASKLIST_LOADING,
  TOGGLE_TASK,
  GET_CLEARED_TASKLIST
} from '../actions/types';

import _ from 'lodash';

const initialState = {
  cleared: [],
  uncleared: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_UNCLEARED_TASKLIST:
      return {
        ...state,
        uncleared: action.payload,
        loading: false
      };
    case GET_CLEARED_TASKLIST:
      return {
        ...state,
        cleared: action.payload,
        loading: false
      };
    case ADD_TASK:
      return {
        ...state,
        uncleared: [...state.uncleared, action.payload]
      };
    case TOGGLE_TASK:
      return {
        ...state,
        uncleared: _.sortBy(
          [
            action.payload,
            ...state.uncleared.filter(task => {
              return task._id !== action.payload._id;
            })
          ],
          '_id'
        )
      };
    case TASKLIST_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
