import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuWithItems from './MenuWithItems';
import { connect } from 'react-redux';
import Modals from './Modals';
import { openModal } from '../../store/actions/modalsActions';
import PropTypes from 'prop-types';

const timeoutLength = 300;

export class Menus extends Component {
  state = {
    anchorEl: null,
    buttonUtentes: false,
    menuUtentes: false,
    buttonRegistos: false,
    menuRegistos: false,
    buttonMed: false,
    menuMed: false,
  };

  handleClick = input => e => {
    e.persist();
    this.setState({
      [input]: true,
      anchorEl: e.currentTarget,
    });
    // this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (e, input, input2) => {
    e.preventDefault();
    this.setState({
      [input]: false,
      [input2]: false,
    });
  };

  enterMenu = (e, input) => {
    e.preventDefault();
    this.setState({
      [input]: true,
    });
  };

  leaveMenu = (e, input, input2) => {
    e.preventDefault();
    setTimeout(() => {
      this.setState({
        [input]: false,
        [input2]: false,
      });
    }, timeoutLength);
  };

  enterButton = input => e => {
    e.preventDefault();
    this.setState({
      [input]: true,
      anchorEl: e.currentTarget,
    });
  };

  // Modals
  handleOpen = modal => e => {
    e.preventDefault();
    return this.props.openModal(modal);
  };

  render() {
    const {
      anchorEl,
      buttonUtentes,
      buttonRegistos,
      buttonMed,
      menuUtentes,
      menuRegistos,
      menuMed,
    } = this.state;

    const { permissions } = this.props;

    const openUtentes = buttonUtentes || menuUtentes;
    const openRegistos = buttonRegistos || menuRegistos;
    const openMedicacao = buttonMed || menuMed;

    const menuItemsMedicacao = [
      {
        label: 'Gerir Medicação',
        menu: 'gerir',
        page: 'medicacao',
        img: '/images/icons/png/pills-tab.png',
        link: '/clinica/medicacao',
      },
      {
        label: 'Preparar Medicação',
        menu: 'preparar',
        page: 'medicacao',
        img: '/images/icons/png/tablets-sm.png',
        link: '/clinica/preparar-medicacao',
      },
    ];

    const menuItemsRegistos = [
      {
        label: 'Enfermagem',
        menu: 'nursing_rec',
        page: 'registo',
        img: '/images/icons/registos/nurse-sm.png',
        modal: this.handleOpen('modalEnfermagem'),
      },
      {
        label: 'Médico',
        menu: 'med_rec',
        page: 'registo',
        img: '/images/icons/registos/medic-sm.png',
        modal: this.handleOpen('modalMedico'),
      },
      {
        label: 'Sinais Vitais',
        menu: 'vitals_rec',
        page: 'registo',
        img: '/images/icons/registos/heart-sm.png',
        modal: this.handleOpen('modalSinaisVitais'),
      },
      {
        label: 'Pensos',
        menu: 'band_rec',
        page: 'registo',
        img: '/images/icons/registos/band-sm.png',
        modal: this.handleOpen('modalPensos'),
      },
      {
        label: 'Quedas',
        menu: 'fall_rec',
        page: 'registo',
        img: '/images/icons/registos/fall-sm.png',
        modal: this.handleOpen('modalQuedas'),
      },
      {
        label: 'Ação Social',
        menu: 'social_rec',
        page: 'registo',
        img: '/images/icons/registos/social-sm.png',
        modal: this.handleOpen('modalSocial'),
      },
      {
        label: 'Nutrição',
        menu: 'nutri_rec',
        page: 'registo',
        img: '/images/icons/registos/nutri-sm.png',
        modal: this.handleOpen('modalNutri'),
      },
    ];

    const menuItemsUtentes = [
      {
        label: 'Lar',
        menu: 'lar',
        page: 'utentes',
        img: '/images/icons/png/hospital-bed-1.png',
        link: '/clinica/utentes/lar',
      },
      {
        label: 'Centro de Dia',
        menu: 'cd',
        page: 'utentes',
        img: '/images/icons/png/sun.png',
        link: '/clinica/utentes/centro-de-dia',
      },
      {
        label: 'Registar Novo',
        menu: 'novo',
        page: 'utentes',
        img: '/images/icons/png/new-user.png',
        link: '/clinica/novo',
      },
    ];

    const menus = [
      { label: 'Início', page: 'inicio', img: '/images/icons/png/home.png', link: '/clinica' },
      {
        label: 'Calendário',
        page: 'calendario',
        img: '/images/icons/png/calendar.png',
        link: '/clinica/calendario',
      },
      {
        label: 'Utentes',
        page: 'utentes',
        img: '/images/icons/png/couple.png',
        ariaOwns: 'menuUtentes',
        handleClick: this.handleClick('buttonUtentes'),
        // onMouseEnter: this.enterButton('buttonUtentes'),
      },
      {
        label: 'Registo',
        page: 'registo',
        img: '/images/icons/png/registo.png',
        ariaOwns: 'menuRegistos',
        handleClick: this.handleClick('buttonRegistos'),
        // onMouseEnter: this.enterButton('buttonRegistos'),
      },
      {
        label: 'Modelos',
        page: 'modelos',
        img: '/images/icons/png/arquivo.png',
        link: '/clinica/modelos',
      },
      {
        label: 'Medicação',
        page: 'medicacao',
        img: '/images/icons/png/tablets-sm.png',
        ariaOwns: 'menuMedicacao',
        handleClick: this.handleClick('buttonMed'),
        // onMouseEnter: this.enterButton('buttonMed'),
      },
      {
        label: 'Histórico',
        page: 'historico',
        img: '/images/icons/png/medical-history.png',
        link: '/clinica/historico',
      },
      {
        label: 'Arquivo',
        page: 'arquivo',
        img: '/images/icons/png/archive.png',
        link: '/clinica/arquivo',
      },
    ];

    const secundarios = [
      {
        id: 'menuUtentes',
        page: 'utentes',
        open: openUtentes,
        menuItems: menuItemsUtentes,
        button: 'buttonUtentes',
        menu: 'menuUtentes',
      },
      {
        id: 'menuRegistos',
        page: 'registo',
        open: openRegistos,
        menuItems: menuItemsRegistos,
        button: 'buttonRegistos',
        menu: 'menuRegistos',
      },
      {
        id: 'menuMedicacao',
        page: 'medicacao',
        open: openMedicacao,
        menuItems: menuItemsMedicacao,
        button: 'buttonMed',
        menu: 'menuMed',
      },
    ];

    const menusPrincipais = menus.map((menu, index) => {
      return permissions.pages[menu.page] ? (
        menu.link ? (
          <Link key={index} to={menu.link}>
            <Button className="text-white">
              <img alt={menu.label} src={menu.img} className="mr-1" />
              {menu.label}
            </Button>
          </Link>
        ) : (
          <Button
            key={index}
            aria-owns={menu.ariaOwns}
            aria-haspopup="true"
            onClick={menu.handleClick}
            // onMouseEnter={menu.onMouseEnter}
            className="text-white"
          >
            <img alt={menu.label} src={menu.img} className="mr-1" />
            {menu.label}
          </Button>
        )
      ) : null;
    });

    const menusSecundarios = secundarios.map((menu, index) => {
      return permissions.pages[menu.page] ? (
        <MenuWithItems
          key={index}
          id={menu.id}
          anchorEl={anchorEl}
          open={menu.open}
          onClose={e => this.handleClose(e, `${menu.button}`, `${menu.menu}`)}
          onClick={e => this.handleClose(e, `${menu.button}`, `${menu.menu}`)}
          // MenuListProps={{
          //   onMouseEnter: e => this.enterMenu(e, `${menu.menu}`),
          //   onMouseLeave: e => this.leaveMenu(e, `${menu.menu}`, `${menu.button}`),
          // }}
          MenuItems={menu.menuItems}
          permissions={permissions}
        />
      ) : null;
    });

    return (
      <>
        {menusPrincipais}
        {menusSecundarios}
        <Modals />
      </>
    );
  }
}

Menus.propTypes = {
  openModal: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  permissions: state.auth.user.permissions,
});

export default connect(
  mapStateToProps,
  {
    openModal,
  }
)(Menus);
