import { createMuiTheme } from '@material-ui/core/styles';
import {
  wiseBlue,
  wiseBlack,
  piedpiper,
  wiseLightPurple,
  // wisePink,
  teste,
  wiseGrey,
  wiseGreen,
  wiseSkin,
  wiseYellow,
  wisePurple,
  wiseRed,
  wiseOrange,
} from './index';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: piedpiper,
    piedpiper,
    wiseBlue,
    teste,
    wiseGrey,
    wiseYellow,
    wiseGreen,
    wiseBlack,
    wiseLightPurple,
    wisePurple,
    wiseRed,
    wiseOrange,
    wiseSkin,
  },
  shape: {
    borderRadius: '15px',
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        minWidth: '0px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        '&:focus': {
          outline: 'none',
        },
      },
      contained: {
        color: 'white',
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
        '&:disabled': {
          opacity: '0',
        },
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1000,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        zIndex: 0,
      },
    },
    MuiOutlinedInput: {
      // input: { borderRadius: 15 },
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px 4px 24px',
      },
      // head: {
      //   fontSize: '1rem',
      // },
      body: {
        fontSize: '1rem',
      },
    },
  },
});
