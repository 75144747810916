/* eslint-disable radix */
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EmailStep from './EmailStep';
import PhoneStep from './PhoneStep';
import PasswordStep from './PasswordStep';
import ConfirmStep from './ConfirmStep';
import { connect } from 'react-redux';
import { registerUser } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import getGreetingTime from '../../utils/getGreetingTime';
import toast from '../../utils/toast';

export class RegisterForm extends Component {
  state = {
    email: '',
    phone: '',
    codePIN: '',
    password: '',
    passwordCheck: '',
    errors: {},
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      toast({
        type: 'success',
        text: `${getGreetingTime()} ${this.props.auth.user.name}.`,
      });
      this.props.history.push('/clinica');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      return this.setState({
        errors: this.props.errors,
      });
    }

    if (this.props.registration !== prevProps.registration) {
      const { registration } = this.props;
      const { email, phone, codePIN } = registration;
      return this.setState({
        email,
        phone,
        codePIN,
      });
    }

    return true;
  }

  onChange = input => e => {
    return e.target ? this.setState({ [input]: e.target.value }) : null;
  };

  onSubmit = e => {
    e.preventDefault();

    const { email, phone, codePIN, password, passwordCheck } = this.state;
    const { step } = this.props.registration;

    const userData = { step, email, phone, codePIN, password, passwordCheck };

    return this.props.registerUser(userData);
  };

  render() {
    const { email, phone, codePIN, password, passwordCheck, errors } = this.state;

    const { step, name } = this.props.registration;

    return (
      <Grid container justify="center" className="mt-6 content">
        <Grid container spacing={16} justify="center">
          <Grid item md={4}>
            <img alt="csclogo" className="w-100" src="/images/wiseLogo.png" />
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Paper className="p-20" style={{ marginTop: 50 }}>
            <Grid
              container
              className="mb-5"
              spacing={16}
              style={{ marginTop: 50 }}
              justify="center"
            >
              <Grid item md={6} className="mx-auto">
                <img alt="csclogo" className="w-100" src="/images/logo.png" />
              </Grid>
            </Grid>
            <Grid container spacing={16} justify="center">
              <Grid item>
                <Typography align="center" variant="h5">
                  Registar
                </Typography>
              </Grid>
              <Grid item md={12}>
                <form noValidate onSubmit={this.onSubmit}>
                  {parseInt(step) >= 1 && parseInt(step) < 5 ? (
                    <EmailStep
                      errors={errors}
                      values={{ email, step }}
                      onChange={this.onChange('email')}
                    />
                  ) : null}
                  {parseInt(step) >= 2 && parseInt(step) < 5 ? (
                    <PhoneStep
                      errors={errors}
                      onChangePhone={this.onChange('phone')}
                      onChangeCodePin={this.onChange('codePIN')}
                      values={{ phone, codePIN, step }}
                    />
                  ) : null}
                  {parseInt(step) === 4 ? (
                    <PasswordStep
                      errors={errors}
                      onChange={this.onChange}
                      values={{ password, passwordCheck, step }}
                    />
                  ) : null}
                  {parseInt(step) >= 5 ? <ConfirmStep values={{ name, step }} /> : null}

                  {parseInt(step) < 5 ? (
                    <Grid container justify="center">
                      <Button type="submit" className="mt-4" color="primary" variant="contained">
                        Continuar
                      </Button>
                    </Grid>
                  ) : null}
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

RegisterForm.defaultProps = {
  errors: {},
};

RegisterForm.propTypes = {
  registerUser: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  registration: state.auth.registration,
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { registerUser }
)(RegisterForm);
