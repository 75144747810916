import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from '../../utils/isEmpty';

const MenuWithItems = ({
  id,
  anchorEl,
  open,
  onClose,
  MenuListProps,
  permissions,
  /* onClick, */ MenuItems,
}) => {
  const menuItems = MenuItems.map((menuItem, index) =>
    permissions.pages[menuItem.page] &&
    permissions.menus[menuItem.page][menuItem.menu] &&
    menuItem.link ? (
      <Link to={menuItem.link} key={index}>
        <MenuItem>
          <img alt={menuItem.label} src={menuItem.img} className="mr-1" />
          {menuItem.label}
        </MenuItem>
      </Link>
    ) : permissions.pages[menuItem.page] && permissions.menus[menuItem.page][menuItem.menu] ? (
      <MenuItem onClick={menuItem.modal} key={index}>
        <img alt={menuItem.label} src={menuItem.img} className="mr-1" />
        {menuItem.label}
      </MenuItem>
    ) : null
  );

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      className={classnames({
        'mt-4': !isEmpty(MenuItems[0].link),
        'mt-5': isEmpty(MenuItems[0].link),
      })}
      MenuListProps={MenuListProps}
    >
      {menuItems}
    </Menu>
  );
};

MenuWithItems.defaultProps = {
  id: null,
  anchorEl: null,
  MenuListProps: null,
};

MenuWithItems.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // onClick: PropTypes.func.isRequired,
  MenuListProps: PropTypes.object,
  MenuItems: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default MenuWithItems;
