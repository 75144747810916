export const wisePrimary = {
  50: '#e8f4f2',
  100: '#c6e3df',
  200: '#a0d1c9',
  300: '#79beb3',
  400: '#5db0a3',
  500: '#40a293',
  600: '#3a9a8b',
  700: '#329080',
  800: '#2a8676',
  900: '#1c7564',
  A100: '#b1ffef',
  A200: '#7effe4',
  A400: '#4bffd9',
  A700: '#31ffd4',
  contrastText: '#fff',
  dark: '#329080',
  light: '#79beb3',
  main: '#40a293',
};

export const wiseWhite = {
  50: '#ffffff',
  100: '#fffefe',
  200: '#fffdfd',
  300: '#fefcfc',
  400: '#fefcfc',
  500: '#fefbfb',
  600: '#fefafa',
  700: '#fefafa',
  800: '#fef9f9',
  900: '#fdf8f8',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'dark',
};

export const piedpiper = {
  50: '#e0efed',
  100: '#b3d6d1',
  200: '#80bbb2',
  300: '#4da093',
  400: '#268b7c',
  500: '#007765',
  600: '#006f5d',
  700: '#006453',
  800: '#005a49',
  900: '#004737',
  A100: '#7bffdb',
  A200: '#48ffcd',
  A400: '#15ffbf',
  A700: '#00fbb6',
  contrastDefaultColor: 'light',
};

export const wiseGrey = {
  50: '#eeeff0',
  100: '#d5d7d9',
  200: '#b9bcbf',
  300: '#9da1a5',
  400: '#888d92',
  500: '#73797f',
  600: '#6b7177',
  700: '#60666c',
  800: '#565c62',
  900: '#43494f',
  A100: '#b1d6fb',
  A200: '#81bdf9',
  A400: '#47a3ff',
  A700: '#2e96ff',
  contrastDefaultColor: 'light',
};

export const teste = {
  50: '#e9f1f8',
  100: '#c8dbee',
  200: '#a3c4e3',
  300: '#7eacd8',
  400: '#639acf',
  500: '#4788c7',
  600: '#4080c1',
  700: '#3775ba',
  800: '#2f6bb3',
  900: '#2058a6',
  A100: '#e0ecff',
  A200: '#adcdff',
  A400: '#7aaeff',
  A700: '#619fff',
  contrastDefaultColor: 'light',
};

export const wiseBlue = {
  50: '#ecf3f7',
  100: '#cee0eb',
  200: '#aecbdd',
  300: '#8eb6cf',
  400: '#75a7c5',
  500: '#5d97bb',
  600: '#558fb5',
  700: '#4b84ac',
  800: '#417aa4',
  900: '#306996',
  A100: '#ddefff',
  A200: '#aad8ff',
  A400: '#77c0ff',
  A700: '#5db4ff',
  contrastText: '#fff',
  dark: '#689ae8',
  light: '#a3c4f2',
  main: '#5d97bb',
};

export const wiseOrange = {
  50: '#fef0e6',
  100: '#fcdac0',
  200: '#fac196',
  300: '#f7a86b',
  400: '#f6954c',
  500: '#f4822c',
  600: '#f37a27',
  700: '#f16f21',
  800: '#ef651b',
  900: '#ec5210',
  A100: '#ffffff',
  A200: '#ffece5',
  A400: '#ffc6b3',
  A700: '#ffb499',
  contrastDefaultColor: 'light',
};

export const wiseSkin = {
  50: '#fffcf6',
  100: '#fef7e8',
  200: '#fdf2d8',
  300: '#fcedc8',
  400: '#fce9bd',
  500: '#fbe5b1',
  600: '#fae2aa',
  700: '#fadea1',
  800: '#f9da98',
  900: '#f8d388',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'light',
};

export const wiseBlack = {
  50: '#e8e8e8',
  100: '#c5c5c5',
  200: '#9f9f9f',
  300: '#797979',
  400: '#5c5c5c',
  500: '#3f3f3f',
  600: '#393939',
  700: '#313131',
  800: '#292929',
  900: '#1b1b1b',
  A100: '#f07575',
  A200: '#eb4747',
  A400: '#ff0000',
  A700: '#e60000',
  contrastDefaultColor: 'light',
};
export const wiseLightPurple = {
  50: '#f9fafc',
  100: '#f0f3f7',
  200: '#e7ebf1',
  300: '#dde2eb',
  400: '#d5dce7',
  500: '#ced6e3',
  600: '#c9d1e0',
  700: '#c2ccdc',
  800: '#bcc6d8',
  900: '#b0bcd0',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'light',
};

export const wisePurple = {
  50: '#f2f0fd',
  100: '#dedafb',
  200: '#c9c2f8',
  300: '#b3aaf5',
  400: '#a297f3',
  500: '#9285f1',
  600: '#8a7def',
  700: '#7f72ed',
  800: '#7568eb',
  900: '#6355e7',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#e5e2ff',
  A700: '#cdc9ff',
  contrastDefaultColor: 'light',
};

export const wiseYellow = {
  50: '#fffff4',
  100: '#fefee4',
  200: '#fefed2',
  300: '#fdfebf',
  400: '#fcfdb2',
  500: '#fcfda4',
  600: '#fcfd9c',
  700: '#fbfc92',
  800: '#fbfc89',
  900: '#fafc78',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#fffffc',
  contrastDefaultColor: 'light',
};
export const wiseGreen = {
  50: '#f1f7f1',
  100: '#dcecdd',
  200: '#c5e0c6',
  300: '#aed3af',
  400: '#9cc99d',
  500: '#8bc08c',
  600: '#83ba84',
  700: '#78b279',
  800: '#6eaa6f',
  900: '#5b9c5c',
  A100: '#ffffff',
  A200: '#d4ffd4',
  A400: '#a1ffa2',
  A700: '#87ff89',
  contrastDefaultColor: 'ligth',
};

export const wiseRed = {
  50: '#fee8e7',
  100: '#fcc7c3',
  200: '#faa19b',
  300: '#f77b72',
  400: '#f65f54',
  500: '#f44336',
  600: '#f33d30',
  700: '#f13429',
  800: '#ef2c22',
  900: '#ec1e16',
  A100: '#ffffff',
  A200: '#ffe9e9',
  A400: '#ffb8b6',
  A700: '#ff9f9c',
  contrastDefaultColor: 'light',
};
