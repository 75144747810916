import React from 'react';
import Select from 'react-select';
import { DropdownIndicator, SingleValue, Option } from '../common';
import customStyles from '../common/customStyles';
import isEmpty from '../../../utils/isEmpty';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';

const ModalSelect = ({ patient, patients, onChange, error, disabled, clearable }) => {
  return error ? (
    <>
      <FormLabel error>{error}</FormLabel>
      <Select
        isDisabled={disabled ? true : false}
        fullWidth
        isClearable={clearable}
        components={{
          DropdownIndicator,
          Option,
          SingleValue,
        }}
        maxMenuHeight="175"
        styles={customStyles}
        onChange={onChange}
        placeholder="Selecionar utente"
        className="mb-3"
        value={patient}
        options={patients}
        getOptionValue={option => option._id}
        getOptionLabel={option => `${option.name} ${option.shortName} ${option.number}`}
        noOptionsMessage={() => {
          if (isEmpty(patients)) {
            return 'A carregar utentes...';
          }
          return 'Nenhum utente satisfaz a pesquisa';
        }}
      />
    </>
  ) : (
    <Select
      isDisabled={disabled ? true : false}
      fullWidth
      isClearable={clearable}
      components={{
        DropdownIndicator,
        Option,
        SingleValue,
      }}
      maxMenuHeight="175"
      styles={customStyles}
      onChange={onChange}
      placeholder="Selecionar utente"
      className="mb-3"
      value={patient}
      options={patients}
      getOptionValue={option => option._id}
      getOptionLabel={option => `${option.name} ${option.shortName} ${option.number}`}
      noOptionsMessage={() => {
        if (isEmpty(patients)) {
          return 'A carregar utentes...';
        }
        return 'Nenhum utente satisfaz a pesquisa';
      }}
    />
  );
};

ModalSelect.defaultProps = {
  patient: {},
  patients: [],
  error: null,
  // editing: null,
  disabled: null,
  clearable: true,
};

ModalSelect.propTypes = {
  // show: PropTypes.bool.isRequired,
  patient: PropTypes.object,
  patients: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  // editing: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default ModalSelect;
