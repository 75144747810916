import {
  GET_OCCURS,
  GET_ERRORS,
  OCCURS_LOADING,
  ADD_OCCUR,
  ADD_PATIENT_TREATMENTS,
  ADD_PATIENT_HISTORY_TREATMENTS,
  END_PATIENT_TREATMENT,
} from './types';
import axios from 'axios';
import { setTreatmentsLoading } from './patientActions';

// Get all occurs
export const loadOccurs = () => dispatch => {
  dispatch(setOccursLoading());

  axios
    .get('/api/occurs')
    .then(res => {
      dispatch({
        type: GET_OCCURS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new occur
export const addOccur = (type, data) => dispatch => {
  axios
    .post(`/api/occurs/${type}`, data)
    .then(res => {
      dispatch({
        type: ADD_OCCUR,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add new bandage occur
export const addBandRec = data => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .post('/api/occurs/band_rec', data, config)
    .then(res => {
      dispatch({
        type: ADD_OCCUR,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patient treatments
export const getPatientTreatments = id => dispatch => {
  dispatch(setTreatmentsLoading());

  axios
    .get(`/api/occurs/treatments/patient/${id}`)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_TREATMENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get patient treatments
export const getPatientHistoryTreatments = id => dispatch => {
  dispatch(setTreatmentsLoading());

  axios
    .get(`/api/occurs/treatments/history/patient/${id}`)
    .then(res => {
      dispatch({
        type: ADD_PATIENT_HISTORY_TREATMENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// End specific treatment for specific patient
export const endPatientTreatment = (patientId, treatmentId, anotacao) => dispatch => {
  axios
    .put(`/api/occurs/treatments/${treatmentId}/patient/${patientId}`, { anotacao })
    .then(res => {
      dispatch({
        type: END_PATIENT_TREATMENT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set loading flag
export const setOccursLoading = () => {
  return {
    type: OCCURS_LOADING,
  };
};
