import moment from 'moment';

const getGreetingTime = () => {
  const m = moment();
  m.locale('pt_PT');
  let g = null; // return g

  if (!m || !m.isValid()) {
    g = 'Bom dia,';
    return g;
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(m.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = 'Boa tarde,';
  } else if (currentHour >= splitEvening) {
    g = 'Boa noite,';
  } else {
    g = 'Bom dia,';
  }

  return g;
};

export default getGreetingTime;
