import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../utils/theme/styles';

const ModalHeader = ({ modalImg, id, title, classes }) => {
  return (
    <Grid className={`${classes.bgOnlyPiedPiper} modal-header mb-3`}>
      <Grid container spacing={16} justify="center" alignItems="center">
        <Grid item>
          {' '}
          <img alt="modalImagem" src={modalImg} />
        </Grid>
        <Grid item>
          <Typography
            className="modal-title text-center m-t-10 m-b-10 text-white"
            id={id}
            variant="h6"
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ModalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  modalImg: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModalHeader);
