import {
  OPEN_ENFERMAGEM,
  CLOSE_ENFERMAGEM,
  OPEN_MEDICO,
  CLOSE_MEDICO,
  OPEN_SINAIS_VITAIS,
  CLOSE_SINAIS_VITAIS,
  OPEN_PENSOS,
  CLOSE_PENSOS,
  OPEN_QUEDAS,
  CLOSE_QUEDAS,
  OPEN_EVENTO,
  CLOSE_EVENTO,
  OPEN_CONSULTA,
  CLOSE_CONSULTA,
  OPEN_ANALISES,
  CLOSE_ANALISES,
  OPEN_ALGALIACAO,
  CLOSE_ALGALIACAO,
  OPEN_PENSO,
  CLOSE_PENSO,
  OPEN_ENTUBACAO,
  CLOSE_ENTUBACAO,
  OPEN_EXAMES,
  CLOSE_EXAMES,
  OPEN_INFO,
  CLOSE_INFO,
  OPEN_LEMBRETE,
  CLOSE_LEMBRETE,
  OPEN_SOCIAL,
  CLOSE_SOCIAL,
  OPEN_NUTRI,
  CLOSE_NUTRI,
} from '../actions/types';

const initialState = {
  modalEnfermagem: false,
  modalMedico: false,
  modalSinaisVitais: false,
  modalPensos: false,
  modalQuedas: false,
  modalSocial: false,
  modalNutri: false,
  modalEvento: false,
  modalEventoConsulta: false,
  modalEventoAlgaliacao: false,
  modalEventoPenso: false,
  modalEventoEntubacao: false,
  modalEventoAnalises: false,
  modalEventoExames: false,
  dataModalInicio: '',
  // dataModalFim: '',
  modalEventoAberto: null,
  modalEventoInfo: false,
  modalEventoLembrete: false,
  modalInfoData: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_ENFERMAGEM:
      return {
        ...state,
        modalEnfermagem: true,
      };
    case CLOSE_ENFERMAGEM:
      return {
        ...state,
        modalEnfermagem: false,
      };
    case OPEN_MEDICO:
      return {
        ...state,
        modalMedico: true,
      };
    case CLOSE_MEDICO:
      return {
        ...state,
        modalMedico: false,
      };
    case OPEN_SINAIS_VITAIS:
      return {
        ...state,
        modalSinaisVitais: true,
      };
    case CLOSE_SINAIS_VITAIS:
      return {
        ...state,
        modalSinaisVitais: false,
      };
    case OPEN_PENSOS:
      return {
        ...state,
        modalPensos: true,
      };
    case CLOSE_PENSOS:
      return {
        ...state,
        modalPensos: false,
      };
    case OPEN_QUEDAS:
      return {
        ...state,
        modalQuedas: true,
      };
    case CLOSE_QUEDAS:
      return {
        ...state,
        modalQuedas: false,
      };
    case OPEN_SOCIAL:
      return {
        ...state,
        modalSocial: true,
      };
    case CLOSE_SOCIAL:
      return {
        ...state,
        modalSocial: false,
      };
    case OPEN_NUTRI:
      return {
        ...state,
        modalNutri: true,
      };
    case CLOSE_NUTRI:
      return {
        ...state,
        modalNutri: false,
      };
    case OPEN_EVENTO:
      return {
        ...state,
        modalEvento: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
      };
    case CLOSE_EVENTO:
      return {
        ...state,
        modalEvento: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_CONSULTA:
      return {
        ...state,
        modalEventoConsulta: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Consulta', color: '#03A9F3' },
      };
    case CLOSE_CONSULTA:
      return {
        ...state,
        modalEventoConsulta: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_ANALISES:
      return {
        ...state,
        modalEventoAnalises: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Analises', color: '#00C292' },
      };
    case CLOSE_ANALISES:
      return {
        ...state,
        modalEventoAnalises: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_ALGALIACAO:
      return {
        ...state,
        modalEventoAlgaliacao: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Algaliacao', color: '#FB9678' },
      };
    case CLOSE_ALGALIACAO:
      return {
        ...state,
        modalEventoAlgaliacao: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_PENSO:
      return {
        ...state,
        modalEventoPenso: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Penso', color: '#FEC107' },
      };
    case CLOSE_PENSO:
      return {
        ...state,
        modalEventoPenso: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_ENTUBACAO:
      return {
        ...state,
        modalEventoEntubacao: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Entubacao', color: '#AB8CE4' },
      };
    case CLOSE_ENTUBACAO:
      return {
        ...state,
        modalEventoEntubacao: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_EXAMES:
      return {
        ...state,
        modalEventoExames: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Exames', color: '#4C5667' },
      };
    case CLOSE_EXAMES:
      return {
        ...state,
        modalEventoExames: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_INFO:
      return {
        ...state,
        modalEventoInfo: true,
        dataModalInicio: action.payload.start,
        // dataModalFim: action.payload.end,
        modalInfoData: action.payload,
      };
    case CLOSE_INFO:
      return {
        ...state,
        modalEventoInfo: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    case OPEN_LEMBRETE:
      return {
        ...state,
        modalEventoLembrete: true,
        dataModalInicio: action.payload.dataInicio,
        // dataModalFim: action.payload.dataFim,
        modalEventoAberto: { value: 'Lembrete', color: '#cc395d' },
      };
    case CLOSE_LEMBRETE:
      return {
        ...state,
        modalEventoLembrete: false,
        modalInfoData: { ...state.modalInfoData, patient_id: null },
        modalEventoAberto: null,
      };
    default:
      return state;
  }
}
