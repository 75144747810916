import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const TextFieldFile = ({ show, onChange, reference, ficheiros, tamanho }) => {
  return show ? (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="addFiles"
        multiple
        ref={reference}
        onChange={onChange}
        type="file"
      />
      <div className="row col-12 align-items-center mb-3">
        <label htmlFor="addFiles">
          <Button variant="outlined" component="span">
            Adicionar foto(s)
          </Button>
        </label>
        {ficheiros.length > 1 && tamanho < 4 ? (
          <Typography className="ml-3">{ficheiros}</Typography>
        ) : ficheiros.length > 1 ? (
          <Typography className="ml-3">{tamanho} ficheiros selecionados</Typography>
        ) : (
          <Typography className="ml-3">Nenhum ficheiro selecionado</Typography>
        )}
      </div>
    </>
  ) : null;
};

TextFieldFile.propTypes = {
  show: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  reference: PropTypes.object,
  ficheiros: PropTypes.string,
  tamanho: PropTypes.number,
};

export default TextFieldFile;
