import {
  OPEN_ENFERMAGEM,
  CLOSE_ENFERMAGEM,
  OPEN_MEDICO,
  CLOSE_MEDICO,
  OPEN_SINAIS_VITAIS,
  CLOSE_SINAIS_VITAIS,
  OPEN_PENSOS,
  CLOSE_PENSOS,
  OPEN_QUEDAS,
  CLOSE_QUEDAS,
  OPEN_EVENTO,
  CLOSE_EVENTO,
  OPEN_CONSULTA,
  CLOSE_CONSULTA,
  OPEN_ANALISES,
  CLOSE_ANALISES,
  OPEN_ALGALIACAO,
  CLOSE_ALGALIACAO,
  OPEN_PENSO,
  CLOSE_PENSO,
  OPEN_ENTUBACAO,
  CLOSE_ENTUBACAO,
  OPEN_EXAMES,
  CLOSE_EXAMES,
  OPEN_INFO,
  CLOSE_INFO,
  OPEN_LEMBRETE,
  CLOSE_LEMBRETE,
  OPEN_SOCIAL,
  CLOSE_SOCIAL,
  OPEN_NUTRI,
  CLOSE_NUTRI,
} from './types';

// OPEN modal
export const openModal = (modal, data) => {
  switch (modal) {
    case 'modalEnfermagem':
      return {
        type: OPEN_ENFERMAGEM,
      };
    case 'modalMedico':
      return {
        type: OPEN_MEDICO,
      };
    case 'modalSinaisVitais':
      return {
        type: OPEN_SINAIS_VITAIS,
      };
    case 'modalPensos':
      return {
        type: OPEN_PENSOS,
      };
    case 'modalQuedas':
      return {
        type: OPEN_QUEDAS,
      };
    case 'modalSocial':
      return {
        type: OPEN_SOCIAL,
      };
    case 'modalNutri':
      return {
        type: OPEN_NUTRI,
      };
    case 'modalEvento':
      return {
        type: OPEN_EVENTO,
        payload: data,
      };
    case 'modalEventoConsulta':
      return {
        type: OPEN_CONSULTA,
        payload: data,
      };
    case 'modalEventoAnalises':
      return {
        type: OPEN_ANALISES,
        payload: data,
      };
    case 'modalEventoAlgaliacao':
      return {
        type: OPEN_ALGALIACAO,
        payload: data,
      };
    case 'modalEventoPenso':
      return {
        type: OPEN_PENSO,
        payload: data,
      };
    case 'modalEventoEntubacao':
      return {
        type: OPEN_ENTUBACAO,
        payload: data,
      };
    case 'modalEventoExames':
      return {
        type: OPEN_EXAMES,
        payload: data,
      };
    case 'modalEventoInfo':
      return {
        type: OPEN_INFO,
        payload: data,
      };
    case 'modalEventoLembrete':
      return {
        type: OPEN_LEMBRETE,
        payload: data,
      };
    default:
      return true;
  }
};

// CLOSE modal
export const closeModal = modal => {
  switch (modal) {
    case 'modalEnfermagem':
      return {
        type: CLOSE_ENFERMAGEM,
      };
    case 'modalMedico':
      return {
        type: CLOSE_MEDICO,
      };
    case 'modalSinaisVitais':
      return {
        type: CLOSE_SINAIS_VITAIS,
      };
    case 'modalPensos':
      return {
        type: CLOSE_PENSOS,
      };
    case 'modalQuedas':
      return {
        type: CLOSE_QUEDAS,
      };
    case 'modalSocial':
      return {
        type: CLOSE_SOCIAL,
      };
    case 'modalNutri':
      return {
        type: CLOSE_NUTRI,
      };
    case 'modalEvento':
      return {
        type: CLOSE_EVENTO,
      };
    case 'modalEventoConsulta':
      return {
        type: CLOSE_CONSULTA,
      };
    case 'modalEventoAnalises':
      return {
        type: CLOSE_ANALISES,
      };
    case 'modalEventoAlgaliacao':
      return {
        type: CLOSE_ALGALIACAO,
      };
    case 'modalEventoPenso':
      return {
        type: CLOSE_PENSO,
      };
    case 'modalEventoEntubacao':
      return {
        type: CLOSE_ENTUBACAO,
      };
    case 'modalEventoExames':
      return {
        type: CLOSE_EXAMES,
      };
    case 'modalEventoInfo':
      return {
        type: CLOSE_INFO,
      };
    case 'modalEventoLembrete':
      return {
        type: CLOSE_LEMBRETE,
      };
    default:
      return true;
  }
};
