import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const EmailStep = ({ values, onChange, errors }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      error={errors.email ? true : false}
      helperText={errors ? errors.email : null}
      label="Email"
      disabled={values.step > 1 ? true : false}
      placeholder="Insira o seu email"
      value={values.email}
      onChange={onChange}
      type="email"
    />
  );
};

EmailStep.defaultProps = {
  errors: {},
};

EmailStep.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default EmailStep;
