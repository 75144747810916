import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConfirmStep = ({ values }) => {
  return (
    <div className="mt-3">
      <Typography align="center" variant="h5">
        {values.name},
      </Typography>
      <Typography align="center" variant="h6">
        O seu registo foi efetuado com sucesso.
      </Typography>
      <Typography align="center" variant="body2" className="mt-1">
        Irá receber um email de forma a concluir e validar o seu registo.
      </Typography>
    </div>
  );
};

ConfirmStep.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ConfirmStep;
