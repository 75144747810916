import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';

const PhoneStep = ({ values, onChangePhone, onChangeCodePin, errors }) => {
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={errors.phone ? true : false}
        helperText={errors ? errors.phone : null}
        label="Número de telemóvel"
        className="mt-4"
        InputProps={{
          startAdornment: <InputAdornment position="start">+351</InputAdornment>,
        }}
        disabled={values.step > 2 ? true : false}
        placeholder="Insira o seu número de telemóvel"
        value={values.phone}
        onChange={onChangePhone}
      />
      {values.step > 2 ? (
        <TextField
          fullWidth
          variant="outlined"
          error={errors.codePIN ? true : false}
          helperText={errors ? errors.codePIN : null}
          label="Código recebido por mensagem"
          className="mt-4"
          disabled={values.step > 3 ? true : false}
          placeholder="Insira o código recebido por mensagem"
          value={values.codePIN}
          onChange={onChangeCodePin}
        />
      ) : null}
    </>
  );
};

PhoneStep.defaultProps = {
  errors: {},
};

PhoneStep.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangePhone: PropTypes.func.isRequired,
  onChangeCodePin: PropTypes.func.isRequired,
};

export default PhoneStep;
