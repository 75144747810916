import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const TextFieldSinal = ({ info }) => {
  return (
    <TextField
      label={`${info.label} ${info.small}`}
      type="number"
      // InputLabelProps={{
      //   shrink: true,
      // }}
      // helperText={info.small}
      value={info.value}
      variant="outlined"
      name={info.name}
      onChange={info.onChange}
    />
  );
};

TextFieldSinal.propTypes = {
  info: PropTypes.object.isRequired,
};

export default TextFieldSinal;
