import { FILES_LOADING, GET_FILES, ADD_FILE, DELETE_FILE } from '../actions/types';

const initialState = {
  files: [],
  loading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FILES:
      return {
        ...state,
        files: action.payload,
        loading: false,
      };
    case ADD_FILE:
      return {
        ...state,
        files: [...action.payload, ...state.files],
        loading: false,
      };
    case DELETE_FILE:
      return {
        ...state,
        files: [
          ...state.files.filter(file => {
            return file.file_id !== action.payload.file_id;
          }),
        ],
        loading: false,
      };
    case FILES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
